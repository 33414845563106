import { useState, useEffect } from 'react'
import { joinClassNames } from 'utils'
import RenderMarkdown from 'components/RenderMarkdown'
import { useDispatch, useSelector } from 'react-redux'
import { markAsRead, sendReply, fetchOneConversation } from 'redux/conversationSlice'
import 'styles/messages.scss'
import { FaPaperPlane } from 'react-icons/fa'

const RenderConversation = ({ conversation }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const messages = useSelector((state) => state.conversations.messages.entities)

  const user_initiated = conversation.creator_id === user.id
  const [reply, setReply] = useState('')

  useEffect(() => {
    dispatch(markAsRead({ conversation_id: conversation.id }))
    dispatch(fetchOneConversation({ conversationId: conversation.id }))
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (reply.trim() === '') {
      return
    }
    const replyData = {
      conversation_id: conversation.id,
      body: reply,
    }

    dispatch(sendReply({ reply: replyData })).then((data) => {
      dispatch(fetchOneConversation({ conversationId: conversation.id }))
      setReply('')
    })
  }

  const interlocutorName = user_initiated ? conversation.receiver_name : conversation.creator_name

  return (
    <div className="container">
      <div className="messages-container mb-4">
        <h3>{conversation.title}</h3>
        <p>Conversation with {interlocutorName}</p>

        <p className="text-center fst-italic">
          ~{' '}
          {user_initiated
            ? 'You started this conversation'
            : `Conversation started by ${conversation.creator_name}`}{' '}
          ~
        </p>
        {conversation.message_ids.map((message_id, index) => {
          const message = messages[message_id]
          const userWasSender = message.sender_id == user.id
          const messageClass = userWasSender ? 'message user' : 'message'

          return (
            <div
              key={`message-${message.id}`}
              className={joinClassNames(['message', `p-3 border`, messageClass, 'shadow-sm'])}
            >
              <div>
                <RenderMarkdown markdownText={message.body} />
              </div>
            </div>
          )
        })}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="message-input mb-3">
          <textarea
            rows={4}
            className="message-input-field form-control bg-white"
            placeholder="New Message..."
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          ></textarea>
          <button type={'submit'} className="message-submit-button">
            <FaPaperPlane />
          </button>
        </div>
      </form>
    </div>
  )
}

export default RenderConversation
