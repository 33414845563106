import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchAllCollections, resetCollection, removeCollection } from './redux/collectionsSlice'
import { fetchSheetsType, removeSheet } from './redux/allSheetsSlice'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'react-bootstrap/Button'
import { Dna } from 'react-loader-spinner'
import { FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import PageMenu from './PageMenu'
import _ from 'lodash'
import axios from 'axios'
import date from 'date-and-time'
import { resetSheet } from './redux/userSheetSlice'

function ConfirmModal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete this collection? There is no 'Undo' button for this.
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="cancel-delete"
            variant="secondary"
            onClick={() => {
              handleClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            data-purpose="confirm-delete"
            onClick={() => {
              handleClose(true)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function CollectionList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const displaySettings = useSelector((state) => {
    return state.team.settings
  })

  const [show, setShow] = useState(false)
  const [collectionToDelete, setCollectionToDelete] = useState(null)
  const [onlyShowMine, setOnlyShowMine] = useState(false)
  const [filteringCategories, setFilteringCategories] = useState([])
  const [searchFilter, setFilter] = useState('')
  const [showFullSheetList, setShowFullSheetList] = useState({})

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const pageBaseUrl = '/collections'
  const pageLimit = 60

  const allSheets = useSelector((state) => state.allSheets.entities)

  const collectionsLoading = useSelector((state) => state.collections.loading)

  let collections = useSelector((state) => {
    const savedCollections = _.omit(state.collections.collections, 'new-collection')
    return _.orderBy(savedCollections, ['created_at'], ['desc'])
  })

  const totalPages = useSelector((state) => {
    return 1
  })

  const showPageMenu = !onlyShowMine && searchFilter === '' && totalPages > 1

  const handleCloseDeleteModal = async (confirm) => {
    setShow(false)
    if (confirm) {
      const deleteResponse = await axios.post('/api/user/collections/delete', {
        collection_id: collectionToDelete,
      })

      if (deleteResponse.data.success) {
        dispatch(removeCollection({ collection_id: collectionToDelete }))
      }
    }
    setCollectionToDelete(null)
  }

  useEffect(() => {
    dispatch(fetchAllCollections())
  }, [dispatch])

  const tableColumns = [
    {
      th: (
        <th className="text-center" scope="col">
          COLLECTION NAME
        </th>
      ),
      td: (collection, settings = {}) => {
        return (
          <td className="text-center">
            <Link
              className={`text-${settings.light ? 'black' : 'white'}`}
              to={`/collection/${collection.id}`}
            >
              {collection.title}
            </Link>
          </td>
        )
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          INCLUDED SHEETS
        </th>
      ),
      td: (collection) => {
        let sheets = collection.salesheet_ids.map((sheetId) => allSheets[sheetId] || {})

        if (_.isEmpty(sheets)) {
          return <td></td>
        }
        const longList = sheets.length > 9
        sheets = longList && !showFullSheetList[collection.id] ? sheets.slice(0, 6) : sheets

        return (
          <td>
            <ul>
              {sheets.map((sheet, i) => {
                return <li key={`sheet-${sheet.id}`}>{sheet.title}</li>
              })}
            </ul>
            {longList && (
              <>
                {showFullSheetList[collection.id] ? (
                  <button
                    onClick={() =>
                      setShowFullSheetList({
                        ...showFullSheetList,
                        [collection.id]: false,
                      })
                    }
                    className="btn btn-light"
                  >
                    Show Less
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      setShowFullSheetList({
                        ...showFullSheetList,
                        [collection.id]: true,
                      })
                    }
                    className="btn btn-light mb-2"
                  >
                    Show {collection.salesheet_ids.length - 6} More
                  </button>
                )}
              </>
            )}
          </td>
        )
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          CREATED
        </th>
      ),
      td: (collection) => {
        return (
          <td className="text-center">
            {date.format(new Date(collection.created_at), 'MMM D YYYY')}
          </td>
        )
      },
    },
    // {
    //   th: (
    //     <th className="text-center" scope="col">
    //       CREATED BY
    //     </th>
    //   ),
    //   td: (sheet) => {
    //     return <td className="text-center">{sheet.creator_nickname}</td>
    //   },
    // },
    {
      th: (
        <th className="text-center" scope="col">
          DELETE
        </th>
      ),
      td: (collection) => {
        return (
          <td
            onClick={() => {
              setCollectionToDelete(collection.id)
              setShow(true)
            }}
            className="ps-4 selectable text-center"
            data-sheet-id={collection.id}
          >
            <FaTrash />
          </td>
        )
      },
    },
  ]

  return (
    <div className="container">
      <p className="my-2">
        This feature allows your team to create collections of talent sheets. You will then be able
        to share multiple talent sheets using a single link.
      </p>
      <button
        data-purpose="create-new-collection"
        className="btn btn-primary my-3"
        onClick={() => {
          dispatch(resetCollection({ collection_id: 'new-collection' }))
          const navTo = '/collection/new-collection'
          navigate(navTo)
        }}
      >
        Create New Collection
      </button>

      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <table
        className={`table table-${
          displaySettings.displayTableColor ? displaySettings.displayTableColor : 'dark'
        } table-striped w-100 mt-2`}
      >
        <thead>
          <tr>
            {tableColumns.map((col, i) => {
              return <Fragment key={`th-${i}`}>{col.th}</Fragment>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(_.omit(collections, 'new-collection'), (collection) => {
            return (
              <tr key={`sheet-${collection.id}`} data-type="sheet-table-row">
                {tableColumns.map((col, i) => {
                  return (
                    <Fragment key={`sheet-${collection.id}-td-${i}`}>
                      {col.td(collection, displaySettings)}
                    </Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        {_.isEmpty(collections) && collectionsLoading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <ConfirmModal show={show} handleClose={handleCloseDeleteModal} />
    </div>
  )
}
