import _ from 'lodash'
import { getColorHex, getContrastingTextForBackground, joinClassNames } from 'utils'
import { useEffect, useRef, useState, useMemo } from 'react'
import { setModalShow } from 'redux/modalsSlice'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

export default function TalentSheetBranding({ talentSheet, displaySettings = {} }) {
  const dispatch = useDispatch()
  const titleFont = _.get(talentSheet, 'settings.displayRosterTitleFont') || 'neuton'

  const darkMode = displaySettings.displayDarkMode

  // `displayDarkLogo` says that the logo is already dark, so we don't need to invert it
  const imageShouldInvert = darkMode && !displaySettings.displayDarkLogo

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-black')

  const backgroundColor = useMemo(() => {
    let bgColor = displaySettings?.displayProfileBackground
      ? displaySettings.displayRosterBackgroundColor
      : 'white'
    return bgColor
  }, [displaySettings])

  const backgroundColorHex = useMemo(() => {
    if (!displaySettings?.displayProfileBackground) return getColorHex('white')
    return getColorHex(displaySettings.displayRosterBackgroundColor)
  })

  const styles = {
    backgroundColor: backgroundColorHex,
  }

  useEffect(() => {
    if (!backgroundColorHex) return
    const contrastingTextColor = getContrastingTextForBackground(backgroundColorHex)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [displaySettings.displayProfileBackground])

  if (!(talentSheet.team_image_url || talentSheet.team_name)) {
    return null
  }

  const hasContactInfo =
    !_.isEmpty(talentSheet?.team_contact_email) ||
    !_.isEmpty(talentSheet?.team_phone) ||
    !_.isEmpty(talentSheet?.team_homepage)

  const activateContactModal = () => {
    if (!hasContactInfo) return
    dispatch(setModalShow({ modal: 'contactInfo', show: true }))
  }

  return (
    <div
      ref={mainContainerRef}
      className={joinClassNames([
        `container-fluid`,
        `bottom-drop-shadow`,
        darkMode ? 'dark-mode' : null,
      ])}
      style={styles}
    >
      <div className="row py-2">
        <div
          className={joinClassNames(['col-12 col-md-4', hasContactInfo ? 'selectable' : null])}
          onClick={activateContactModal}
        >
          {talentSheet.team_image_url ? (
            <div className="team-logo-box d-flex justify-content-center justify-content-md-start px-3">
              <div className="team-logo-container">
                <img
                  className={joinClassNames([
                    `profile-team-logo`,
                    imageShouldInvert ? 'dark-mode-image' : null,
                  ])}
                  src={talentSheet.team_image_url}
                />
                {hasContactInfo && (
                  <div className="d-flex flex-column justify-content-center">
                    <button className="btn btn-link" onClick={activateContactModal}>
                      Contact
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="team-name-box d-flex flex-column justify-content-center pb-3 me-2">
              <div className={joinClassNames([`text-bold ps-3`])}>{talentSheet.team_name}</div>
            </div>
          )}
        </div>
        <div className="col-12 col-md-4">
          <h2
            className={joinClassNames([
              `sheet-header-text font-${titleFont} text-center p-2`,
              textColorClass,
            ])}
          >
            {!_.isEmpty(talentSheet.description) ? talentSheet.description : 'Selected Talent'}
          </h2>
        </div>
        {/* {displaySettings.displayResponseOption && (
          <div className="col-12 col-md-4 d-flex justify-content-end align-items-center pr-3">
            <Link
              to={`/public/talent-sheet-response/${talentSheet.id}`}
              className="btn btn-primary"
            >
              Connect
            </Link>
          </div>
        )} */}
      </div>
    </div>
  )
}
