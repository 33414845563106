import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify'
import { fetchBasicInfo, fetchSheetsType } from './redux/allSheetsSlice'
import {
  fetchOneUserSheet,
  setDescription,
  setInfluencers,
  setNote,
  setSaveStatus,
  setTitle,
} from './redux/userSheetSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'

import AccountSearch from './AccountSearch'
import Button from 'react-bootstrap/Button'
import { FaEdit } from 'react-icons/fa'
import { Fragment } from 'react'
import InfluencerFilters from './components/InfluencerFilters'
import LoadingContainer from './components/LoadingContainer'
import Modal from 'react-bootstrap/Modal'
import SocialIcon from './components/SocialIcon'
import _ from 'lodash'
import applyFilters from './applyFilters'
import axios from 'axios'
import { safelyMillify } from './utils'
import { Talent } from 'helpers/Talent'
import { ListManager } from 'react-beautiful-dnd-grid'
import ClickToCopy from './components/ClickToCopy'
import { fetchCastingCalls, fetchResponseSheet } from 'redux/castingCallsSlice'

const ListElement = ({ item }) => {
  return (
    <div className="grid-drag-item">
      <div className={``} data-type="influencer-onsheet">
        <div className="portrait-rounded text-center mb-3">
          <Link
            // target="_blank"
            to={`/influencers/profile/${item.content.id}`}
          >
            <img src={item.content.picture} />
          </Link>
        </div>
        <div className={`col-12 text-center`}>
          <div className="mb-3">
            <p className="w-100">{item.content.fullname}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function TitleModal({ show, handleClose }) {
  const [title, setTitle] = useState('')

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Title Needed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter a title for the sheet</p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleClose(title)
            }}
          >
            <input
              id="modal-sheet-title-input"
              autoFocus
              type="text"
              className="form-control"
              placeholder="Sheet Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <button type="submit" hidden />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="set-title"
            onClick={() => {
              handleClose(title)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
})

export default function EditTalentSheet({ sheetType = 'talent' }) {
  const navigate = useNavigate()
  const params = useParams()

  const user = useSelector((state) => state.user)
  const talentSheet = useSelector((state) => state.userSheet)
  const allInfluencerEntities = useSelector((state) => state.influencers.entities)
  const team_influencer_ids = useSelector((state) => state.influencers.team_influencer_ids)

  const influencers = useMemo(
    () => _.pick(allInfluencerEntities, team_influencer_ids),
    [allInfluencerEntities, team_influencer_ids],
  )

  const socials = useSelector((state) => state.socials.entities)

  const talentSheetInfluencers = talentSheet.influencer_ids

  const tagged_influencers = useSelector((state) => state.tags.tagged_influencers)

  const notes = useSelector((state) => {
    return state.userSheet.notes
  })
  const [error, setError] = useState(null)
  const [editTitle, setEditTitle] = useState(true)
  const [showTitleModal, setShowTitleModal] = useState(false)
  const [showCopySheetModal, setShowCopySheetModal] = useState(false)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('desc')

  const [filteringTags, setFilteringTags] = useState([])
  const [displayedInfluencers, setDisplayedInfluencers] = useState([])
  const [filteredIds, setFilteredIds] = useState([])
  const [nameFilter, setNameFilter] = useState('')

  const [reordering, setReordering] = useState(false)

  const influencerToDraggable = (influencer) => {
    const talent = new Talent(influencer, socials)

    return {
      id: `influencer-${influencer.id}`,
      content: {
        fullname: influencer.fullname,
        picture: talent.getPhotoUrl(),
        email: influencer.email,
        id: influencer.id,
        tags: influencer.tags,
      },
    }
  }

  // usePrompt('Leave this page? You may have unsaved changes.', formIsDirty)

  const showSavedToast = ({ customText = 'Talent Sheet Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const showAdvancedFilters = useSelector((state) => state.searchFilters.use_advanced_filters)

  const toggleAdvancedFilters = () => {
    dispatch({
      type: 'TOGGLE_ADVANCED_FILTERS',
    })
  }

  const influencersWithFollowers = useMemo(
    () =>
      _.map(influencers, (influencer) => {
        const primaryAccount = socials[influencer.primary_account_id] || {}
        return {
          ...influencer,
          primary_account_followers: primaryAccount.followers || 0,
        }
      }),
    [influencers, socials],
  )

  const appliedFilters = useMemo(
    () =>
      applyFilters({
        influencers: influencersWithFollowers,
        filteringTags,
        nameFilter,
        taggedInfluencers: tagged_influencers,
        excludedIds: talentSheet.influencer_ids,
        sortProperty: sortColumn,
        sortDirection,
      }),
    [
      influencersWithFollowers,
      filteringTags,
      nameFilter,
      tagged_influencers,
      talentSheet.influencer_ids,
      sortColumn,
      sortDirection,
    ],
  )

  useEffect(() => {
    setDisplayedInfluencers(appliedFilters)
    if (filteringTags.length === 0 && nameFilter.trim() === '') {
      setFilteredIds(null)
    } else {
      setFilteredIds(appliedFilters.map((inf) => inf.id))
    }
  }, [appliedFilters, filteringTags, nameFilter])

  const toggleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortColumn(null)
    } else {
      setSortColumn(column)
    }
  }

  const toggleSortDirection = () => {
    if (sortDirection === 'desc') {
      setSortDirection('asc')
    } else {
      setSortDirection('desc')
    }
  }

  const setTalentSheetInfluencers = (influencers) => {
    dispatch(setInfluencers(influencers))
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const usedInfluencers = talentSheetInfluencers.map((influencer) => {
    return influencer.id
  })

  let draggableSheetInfluencers = talentSheetInfluencers
    .map((id) => {
      return influencers[id]
    })
    .filter((inf) => inf)
    .map(influencerToDraggable)

  const dispatch = useDispatch()

  useEffect(() => {
    if (params.castingCallId) {
      dispatch(fetchCastingCalls())
      dispatch(fetchResponseSheet(params.castingCallId))
    }
  }, [])

  useEffect(() => {
    const load = async () => {
      if (params.talentSheetId) {
        dispatch(fetchOneUserSheet({ hash_id: params.talentSheetId }))
        setEditTitle(false)
      }
    }
    load()
  }, [dispatch, params.talentSheetId])

  let handleSave = async (receivedTitle) => {
    const sheetTitle = receivedTitle || talentSheet.title

    const data = {
      title: sheetTitle,
      sheet_type: sheetType,
    }

    if (params.castingCallId) {
      data.casting_call_id = params.castingCallId
    }

    if (params.talentSheetId) {
      data.sheet_id = params.talentSheetId
    }

    data.influencers = talentSheetInfluencers.map((influencer_id) => {
      return {
        influencer_id,
        note: notes[influencer_id],
      }
    })

    if (_.isEmpty(data.title) && sheetType !== 'casting_call_response') {
      setShowTitleModal(true)
      return
    }

    try {
      const res = await axios.post('/api/user/talent-sheets/save', data)
      dispatch(setSaveStatus('saved'))
      showSavedToast()

      if (params.castingCallId) {
        return
      }

      if (!params.talentSheetId) {
        console.log('no id, navigating')
        dispatch(fetchBasicInfo())
        dispatch(fetchSheetsType({ sheet_type: sheetType, limit: 10 }))
        const navTo =
          sheetType === 'talent'
            ? `/talent-sheet/${res.data.sheet_id}`
            : `/campaign/${res.data.sheet_id}`
        navigate(navTo)
      }
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      setError(error)
      window.setTimeout(() => {
        setError(null)
      }, 2500)
    }
  }

  const handleSubmitResponse = async () => {
    // this is a creator call response, so we need to submit it
    const data = {
      casting_call_id: params.castingCallId,
      sheet_id: params.talentSheetId,
    }

    try {
      const res = await axios.post('/api/user/casting-call-response-submit', data)
      dispatch(setSaveStatus('submitted'))
      showSavedToast({ customText: 'Response Submitted' })
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      setError(error)
      window.setTimeout(() => {
        setError(null)
      }, 2500)
    }
  }

  const handleAdd = (influencer_id) => {
    const nextInfluencers = [...talentSheet.influencer_ids, influencer_id]
    dispatch(setInfluencers(nextInfluencers))
  }

  const handleAddMultiple = (influencer_ids) => {
    const nextInfluencers = _.uniq([...talentSheet.influencer_ids, ...influencer_ids])
    dispatch(setInfluencers(nextInfluencers))
  }

  const handleRemove = (influencer_id) => {
    const new_influencer_ids = talentSheet.influencer_ids.filter((id) => {
      return id !== influencer_id
    })

    dispatch(setInfluencers(new_influencer_ids))
  }

  function onDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    const new_influencer_ids = reorder(talentSheet.influencer_ids, source.index, destination.index)
    dispatch(setInfluencers(new_influencer_ids))
  }

  const allSheets = useSelector((state) => state.allSheets.entities)
  const castingCall = useSelector((state) => {
    const castingCall = _.find(state.castingCalls, (cc) => {
      return cc.id === params.castingCallId
    })
    return castingCall
  })

  const shortenText = (text) => {
    if (text.length > 100) {
      return text.slice(0, 97) + '…'
    } else {
      return text
    }
  }

  if (_.isEmpty(talentSheet) || talentSheet.loading === 'pending') return <LoadingContainer />

  const AdvancedFilterButton = () => {
    return (
      <div className="d-flex flex-row justify-content-center my-2">
        <button
          className="btn btn-secondary my-2"
          onClick={() => {
            toggleAdvancedFilters()
          }}
        >
          {showAdvancedFilters ? 'Hide Advanced Filters' : 'Filter by Talent/Audience Stats'}
        </button>
      </div>
    )
  }

  const SaveButton = () => {
    return (
      <div className="d-flex flex-row align-content-center my-2">
        <button
          onClick={() => {
            handleSave()
          }}
          className="btn btn-primary btn-lg my-2"
          data-purpose="save-talent-sheet"
          disabled={usedInfluencers.length === 0}
        >
          Save Sheet
        </button>
        <div className={`message-box ${error ? 'error' : ''}`}>
          {error && (
            <div className="ms-4">
              <p>{error}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const savePublicSheetHeading = (newHeading) => {
    return axios.post('/api/user/talent-sheets/public-title', {
      public_title: newHeading,
      sheet_id: params.talentSheetId,
    })
  }

  let headingText = 'New Talent Sheet'
  if (sheetType === 'casting_call_response') {
    headingText = `Response to: ${castingCall?.title}`
  }

  return (
    <div className="container pt-3">
      {!params.talentSheetId && <h2 className="text-center mb-3">{headingText}</h2>}

      {params.castingCallId && (
        <div className="row">
          <div className="col-12">
            <p>
              Note: This sheet is a response to a Creator Call, and will not be visible until you
              choose to "Submit" it.
            </p>
          </div>
        </div>
      )}

      {sheetType === 'talent' && (
        <div className="my-1">
          {params.talentSheetId && (
            <div className="d-flex flex-row fs-5">
              <p className="me-3">Public Links: </p>
              <div className="me-3">
                <Link
                  data-purpose="public-sheet-link"
                  to={`/public/talent-sheet/${params.talentSheetId}`}
                >
                  Single-Page View
                </Link>
                <ClickToCopy
                  iconId={'page-view-copy-icon'}
                  textToCopy={`${window.location.origin}/public/talent-sheet/${params.talentSheetId}`}
                />
              </div>
              <div>
                <Link
                  data-purpose="roster-view-public-sheet-link"
                  to={`/public/talent-sheet/roster-view/${params.talentSheetId}`}
                >
                  Roster View
                </Link>
                <ClickToCopy
                  iconId={'roster-view-copy-icon'}
                  textToCopy={`${window.location.origin}/public/talent-sheet/roster-view/${params.talentSheetId}`}
                />
              </div>
            </div>
          )}
          <hr />
        </div>
      )}

      <div className="d-flex flex-column justify-content-around">
        {sheetType !== 'casting_call_response' && (
          <>
            {editTitle ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setEditTitle(false)
                }}
              >
                <input
                  id="sheet-title-input"
                  className="w-50 mb-3 form-control"
                  type="text"
                  value={talentSheet.title}
                  onChange={(e) => {
                    dispatch(setTitle(e.target.value))
                  }}
                  placeholder="Title"
                  data-purpose="sheet-title"
                />
                <input type="submit" hidden />
              </form>
            ) : (
              <h2
                data-purpose="sheet-title"
                className="selectable"
                onClick={() => setEditTitle(true)}
              >
                {talentSheet.title}{' '}
                <span className="ms-3">
                  <FaEdit />
                </span>
              </h2>
            )}
          </>
        )}

        {sheetType === 'campaign' && (
          <div className="mt-2 mb-3">
            <select
              onChange={(e) => {
                if (e.target.value === 'default') return
                let target_influencers = _.find(
                  allSheets,
                  (s) => s.id === e.target.value,
                ).influencers
                target_influencers = _.orderBy(target_influencers, (ti) => ti.position)
                setTalentSheetInfluencers(target_influencers)
              }}
              className="form-select"
            >
              <option value={'default'}>Select an existing sheet to load talent...</option>
              {_.map(allSheets, (sheet) => {
                if (sheet.sheet_type === 'campaign') return null
                return (
                  <option key={`sheet-${sheet.id}`} value={sheet.id}>
                    {sheet.title} (
                    {shortenText(
                      _.orderBy(sheet.influencers, 'position')
                        .map((inf) => inf.fullname)
                        .join(', '),
                    )}
                    )
                  </option>
                )
              })}
            </select>
          </div>
        )}

        {sheetType === 'talent' && params.talentSheetId ? (
          <div className="my-2">
            <button
              className="btn btn-link"
              onClick={() => {
                setShowCopySheetModal(true)
              }}
            >
              Copy Talent to New Sheet
            </button>
          </div>
        ) : (
          <div className="my-2">
            {castingCall?.response_sheet_id &&
              ['saved', 'clean'].includes(talentSheet.saveStatus) && (
                <Link
                  to={`/creator-call-response/preview/${params.castingCallId}`}
                  className="btn btn-link"
                >
                  Preview Link
                </Link>
              )}
          </div>
        )}

        <TitleModal
          show={showCopySheetModal}
          handleClose={(receivedTitle) => {
            if (_.isEmpty(receivedTitle) || receivedTitle === talentSheet.title) {
              setShowCopySheetModal(false)
              return
            }
            axios
              .post('/api/user/talent-sheets/copy', {
                sheet_id: params.talentSheetId,
                new_sheet_title: receivedTitle,
              })
              .then((res) => {
                dispatch(fetchSheetsType({ sheet_type: 'talent' }))
                dispatch(fetchBasicInfo())
                navigate(`/talent-sheet/${res.data.sheet_id}`)
              })
            setShowCopySheetModal(false)
          }}
        />
        <div>
          {params.talentSheetId && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={talentSheet.usePublicTitle}
                id="displayTitleCheck"
                onChange={(e) => {
                  if (!e.target.checked) {
                    dispatch(setDescription(null))
                    savePublicSheetHeading(null).then(() => {
                      showSavedToast({ customText: 'Public Title Removed' })
                    })
                  } else {
                    dispatch(setDescription(''))
                  }
                }}
              />
              <label
                style={{ fontSize: '1.2em' }}
                className="form-check-label"
                htmlFor="displayTitleCheck"
              >
                Replace default heading (instead of "Selected Talent")
              </label>
            </div>
          )}

          {!_.isEmpty(params.talentSheetId) && talentSheet.usePublicTitle && (
            <>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  savePublicSheetHeading(talentSheet.description).then((res) => {
                    if (res.data.success) {
                      showSavedToast({ customText: 'Public Title Saved' })
                    }
                  })
                }}
              >
                <div className="w-50 d-flex flex-row">
                  <div>
                    <input
                      id="sheet-public-title-input"
                      className="form-control my-3"
                      type="text"
                      value={talentSheet.description || ''}
                      placeholder={'Custom Heading'}
                      onChange={(e) => {
                        dispatch(setDescription(e.target.value))
                      }}
                    />
                  </div>

                  <div className="d-flex py-3 ms-2">
                    <input type="submit" className="btn btn-primary" value="Save Heading" />
                  </div>
                </div>
              </form>
            </>
          )}
        </div>

        {talentSheet.influencer_ids.length > 3 && <SaveButton />}

        <div className="my-3">
          {reordering ? (
            <button className="btn btn-light" onClick={() => setReordering(false)}>
              Toggle Normal View
            </button>
          ) : (
            <button className="btn btn-light" onClick={() => setReordering(true)}>
              Toggle Grid View for Reordering
            </button>
          )}
        </div>

        {reordering ? (
          <ListManager
            items={draggableSheetInfluencers}
            direction="horizontal"
            maxItems={5}
            render={(item) => <ListElement item={item} />}
            onDragEnd={(sourceIndex, destinationIndex) => {
              onDragEnd({
                source: { index: sourceIndex },
                destination: { index: destinationIndex },
              })
            }}
          />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <>
              {usedInfluencers.length === 0 ? (
                <p style={{ fontSize: '1.2em' }} className="text-bold">
                  Add influencers from list below to get started
                </p>
              ) : (
                <Droppable droppableId={'0'}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={`talent-sheet-list mb-4 shadow container ${sheetType}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {draggableSheetInfluencers.map((item, itemIndex) => (
                          <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`drag-item shadow-sm p-3 my-3`}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <div
                                  className={`row justify-content-between`}
                                  data-type="influencer-onsheet"
                                >
                                  <div className="col-2 portrait-rounded align-self-center">
                                    <Link
                                      // target="_blank"
                                      to={`/influencers/profile/${item.content.id}`}
                                    >
                                      <img alt="talent portrait" src={item.content.picture} />
                                    </Link>
                                  </div>
                                  <div className={`col-8 fs-3 text-center`}>
                                    <div className="col-12 mb-3">{item.content.fullname}</div>
                                    <div className="col-12 justify-content-center">
                                      <textarea
                                        className="form-control"
                                        data-note-id={item.content.id}
                                        style={{ width: '100%', height: '90px' }}
                                        placeholder={`Optional: Display note about influencer (e.g. why are they a good fit for this campaign?)\n\nLinks (if any) should use this format: [Link Text](https://your-url.com)`}
                                        value={
                                          notes[item.content.id] ? notes[item.content.id] : ''
                                        }
                                        onChange={(e) => {
                                          dispatch(
                                            setNote({
                                              id: item.content.id,
                                              note: e.target.value,
                                            }),
                                          )
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div className="col-2 d-flex flex-column">
                                    <div className="pt-2">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm mb-3"
                                        data-purpose="delete-sheet"
                                        onClick={() => {
                                          handleRemove(item.content.id)
                                        }}
                                      >
                                        REMOVE
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              )}

              <SaveButton />

              <h2 className="text-center">Available Roster</h2>

              <InfluencerFilters
                filteringTags={filteringTags}
                setFilteringTags={setFilteringTags}
                setNameFilter={setNameFilter}
                allowHide={false}
              />

              {showAdvancedFilters ? (
                <>
                  <AdvancedFilterButton />
                  <AccountSearch
                    pageLimit={300}
                    handleAddToTalentSheet={handleAdd}
                    handleAddMultiple={handleAddMultiple}
                    excludedIds={talentSheetInfluencers}
                    onlyShowIds={filteredIds}
                    team="self"
                    profileType="influencers"
                  />
                </>
              ) : (
                <>
                  {_.get(user, 'teamSettings.selfSearch') === true && <AdvancedFilterButton />}
                  <div className="row my-2">
                    <div className="col-12">
                      <button
                        className="btn btn-info text-white btn-lg"
                        onClick={() => {
                          const visibleInfluencerIds = _.map(displayedInfluencers, 'id')
                          handleAddMultiple(visibleInfluencerIds)
                        }}
                      >
                        Add All to Sheet
                      </button>
                    </div>
                  </div>
                  <div className="mb-3 p-2 shadow">
                    <table className="table talent-sheet-table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th
                            scope="col"
                            className="selectable text-center"
                            onClick={() => {
                              if (sortColumn === 'fullname') {
                                toggleSortDirection()
                              } else {
                                toggleSortColumn('fullname')
                              }
                            }}
                          >
                            Name{' '}
                            {sortColumn === 'fullname' && (
                              <Fragment>{sortDirection === 'desc' ? '▼' : '▲'}</Fragment>
                            )}
                          </th>
                          <th
                            scope="col"
                            className="selectable text-center"
                            onClick={() => {
                              if (sortColumn === 'primary_account_followers') {
                                toggleSortDirection()
                              } else {
                                toggleSortColumn('primary_account_followers')
                              }
                            }}
                          >
                            Followers (Primary){' '}
                            {sortColumn === 'primary_account_followers' && (
                              <Fragment>{sortDirection === 'desc' ? '▼' : '▲'}</Fragment>
                            )}
                          </th>
                          <th style={{ width: '35%' }} scope="col" className="text-center">
                            Tags
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedInfluencers.map((influencer) => {
                          const primaryAccount = socials[influencer.primary_account_id] || {}
                          return (
                            <tr
                              key={`influencer-${influencer.id}`}
                              data-type="available-influencer"
                              data-influencer-id={influencer.id}
                            >
                              <td className="portrait-rounded">
                                <Link to={`/influencers/profile/${influencer.id}`}>
                                  <img
                                    alt="talent portrait"
                                    src={primaryAccount.picture_main_url}
                                  />
                                </Link>
                              </td>
                              <td className="align-middle text-center">{influencer.fullname}</td>
                              <td className="align-middle text-center">
                                {primaryAccount.followers && (
                                  <Fragment>
                                    <span className="pe-2">
                                      <SocialIcon platform={primaryAccount.platform} />
                                    </span>
                                    {safelyMillify(primaryAccount.followers)}{' '}
                                  </Fragment>
                                )}
                              </td>
                              <td className="align-middle">
                                {_.get(influencer, 'tags') && influencer.tags.join(', ')}
                              </td>
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  data-purpose="add-influencer"
                                  onClick={() => {
                                    handleAdd(influencer.id)
                                  }}
                                >
                                  ADD
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          </DragDropContext>
        )}

        <TitleModal
          show={showTitleModal}
          handleClose={(receivedTitle) => {
            setShowTitleModal(false)
            handleSave(receivedTitle)
          }}
        />
      </div>
    </div>
  )
}
