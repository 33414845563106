import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { CollectionSheet, TeamSettings } from 'types'

interface Collection {
  id: null | string
  title: string
  salesheet_ids: string[]
  sheets?: {
    [sheetId: string]: {
      cover_photo_url?: string
    }
  }
  displaySettings?: Partial<TeamSettings>
}

interface InfluencerBasicInfo {
  id: string
  fullname: string
  custom_picture: string | null
  picture_main_url: string
}

interface CollectionsState {
  loading: boolean
  total_collections: null | number
  collections: {
    [collectionId: string]: Collection
  }
  influencersBasicInfo: Record<string, InfluencerBasicInfo>
  sheetBasicInfo: Record<string, CollectionSheet>
}

interface FetchCollectionPayload {
  collection_id: string
  publicAccess?: boolean
}

interface UpdateCollectionSheetPayload {
  collection_id: string
  sheet_id: string
  updates: { [key: string]: any }
}

interface ChangeCoverPhotoPayload {
  collection_id: string
  sheet_id: string
  cover_photo_url: string
}

interface SetTitlePayload {
  collection_id: string
  title: string
}

interface AddSheetToCollectionPayload {
  collection_id: string
  sheet_id: string
}

interface ResetCollectionPayload {
  collection_id: string
}

interface SetSalesSheetsPayload {
  collection_id: string
  salesheet_ids: string[]
}

interface RemoveCollectionPayload {
  collection_id: string
}

export const fetchAllCollections = createAsyncThunk('collections/fetchAll', async () => {
  const response = await axios.get('/api/user/collections')
  return response.data
})

export const fetchCollection = createAsyncThunk(
  'collections/fetch',
  async ({ collection_id, publicAccess = false }: FetchCollectionPayload) => {
    const route = publicAccess ? '/api/public/collections' : '/api/user/collections'
    const response = await axios.get(`${route}/${collection_id}`)
    return response.data
  },
)

export const fetchPublicCollection = createAsyncThunk(
  'collections/fetchPublic',
  async ({ collection_id }: { collection_id: string }) => {
    const response = await axios.get(`/api/public/collections/${collection_id}`)
    return response.data
  },
)

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: {
    loading: false,
    total_collections: null,
    collections: {
      'new-collection': {
        id: null,
        title: '',
        salesheet_ids: [],
      },
    },
    influencersBasicInfo: {},
    sheetBasicInfo: {},
  } as CollectionsState,
  reducers: {
    updateCollectionSheet: (state, { payload }: PayloadAction<UpdateCollectionSheetPayload>) => {
      state.collections[payload.collection_id].sheets![payload.sheet_id] = {
        ...(state.collections[payload.collection_id].sheets![payload.sheet_id] || {}),
        ...payload.updates,
      }
    },
    changeCoverPhoto: (state, { payload }: PayloadAction<ChangeCoverPhotoPayload>) => {
      state.collections[payload.collection_id].sheets![payload.sheet_id].cover_photo_url =
        payload.cover_photo_url
    },
    setTitle: (state, { payload }: PayloadAction<SetTitlePayload>) => {
      state.collections[payload.collection_id].title = payload.title
    },
    addSheetToCollection: (state, { payload }: PayloadAction<AddSheetToCollectionPayload>) => {
      const existing_sheets = state.collections[payload.collection_id].salesheet_ids
      state.collections[payload.collection_id].salesheet_ids = [
        ...existing_sheets,
        payload.sheet_id,
      ]
    },
    resetCollection: (state, { payload }: PayloadAction<ResetCollectionPayload>) => {
      state.collections[payload.collection_id] = {
        id: null,
        title: '',
        salesheet_ids: [],
      }
    },
    setSalesSheets: (state, { payload }: PayloadAction<SetSalesSheetsPayload>) => {
      state.collections[payload.collection_id].salesheet_ids = payload.salesheet_ids
    },
    removeCollection: (state, { payload }: PayloadAction<RemoveCollectionPayload>) => {
      delete state.collections[payload.collection_id]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCollections.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllCollections.fulfilled, (state, { payload }) => {
        state.loading = false
        state.collections = {
          ...state.collections,
          ...payload.collections,
        }
      })
      .addCase(fetchCollection.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.loading = false
        state.collections = {
          ...state.collections,
          ...payload.collections,
        }
        state.influencersBasicInfo = payload.influencers.reduce(
          (acc: Record<string, InfluencerBasicInfo>, inf: InfluencerBasicInfo) => {
            acc[inf.id] = inf
            return acc
          },
          {},
        )

        state.sheetBasicInfo = payload.sheets.reduce(
          (acc: Record<string, CollectionSheet>, sheet: CollectionSheet) => {
            acc[sheet.id] = sheet
            return acc
          },
          {},
        )
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setTitle,
  changeCoverPhoto,
  updateCollectionSheet,
  addSheetToCollection,
  resetCollection,
  setSalesSheets,
  removeCollection,
} = collectionsSlice.actions

export default collectionsSlice.reducer
