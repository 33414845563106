import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'
import { Influencer, Social, Sheet } from 'types'

interface TalentSheetState {
  sheet_id: string | null
  loading: boolean
  settings: Record<string, unknown>
  sheet: Sheet
  error?: string
}

interface FetchProfilePayload {
  salesheet_id: string
  influencer_id: string
  // team_id: string
}

interface FetchProfileResponse {
  influencer: Influencer
  socials: Social[]
}

interface FetchSingleSheetResponse {
  sheet: Sheet
  influencers: Influencer[]
  socials: Social[]
}

interface LoadPublicSheetPayload {
  hash_id: string
  fetch_all?: boolean
  page?: number
  limit?: number
}

export const fetchSingleProfileForSheet = createAsyncThunk<
  FetchProfileResponse,
  FetchProfilePayload
>('talentsheet/public/fetchProfile', async ({ salesheet_id, influencer_id }) => {
  const params = {
    salesheet_id,
    influencer_id,
  }
  const response = await axios.get<FetchProfileResponse>(`/api/public/talent-sheet-profile`, {
    params,
  })
  return response.data
})

export const loadPublicSheet = createAsyncThunk<
  FetchSingleSheetResponse | { error: string },
  LoadPublicSheetPayload,
  { rejectValue: { error: string } }
>('/talentSheet/loadPublicSheet', async ({ hash_id, fetch_all = false, page = 1, limit = 15 }) => {
  const params = {
    page: fetch_all ? 1 : page,
    limit: fetch_all ? 1000 : limit,
  }
  let url = `/api/public/talent-sheets/${hash_id}`

  try {
    const { data } = await axios.get<FetchSingleSheetResponse>(url, { params })
    if (_.isEmpty(data)) {
      return {
        error: 'Something went wrong! No talent sheet was found',
      }
    }
    return data
  } catch (e) {
    return { error: 'Something went wrong! No talent sheet was found' }
  }
})

export const talentSheetSlice = createSlice({
  name: 'talentSheet',
  initialState: {
    sheet_id: null,
    loading: false,
    settings: {},
    sheet: {},
  } as TalentSheetState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.sheet.title = action.payload
    },
  },
})

export const { setTitle } = talentSheetSlice.actions

export default talentSheetSlice.reducer
