const formatAsPct = (num) => {
  return `${(num * 100).toFixed(1)}%`
}

const pythonFormToDisplay = (s) => {
  // replace underscores with spaces and capitalize first letter of each word
  return s.replace(/_/g, ' ').replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

const LikesNotFromFollowersWidget = ({ data }) => {
  if (!data.likes_not_from_followers) return null
  return (
    <>
      <h4 className="text-center headline mb-3">Likes Not From Followers</h4>
      <h3 className="text-center pt-3">{formatAsPct(data.likes_not_from_followers)}</h3>
    </>
  )
}

export default LikesNotFromFollowersWidget
