import AccountSearch from './AccountSearch'
import CollectionList from './CollectionList'
import TeamTalentPrivateRoster from './TeamTalentPrivateRoster'
import SearchFavorites from './SearchFavorites'
import TagViewer from './TagViewer'
import TalentSheetList from './TalentSheetList'
import Conversations from 'Conversations'
import _ from 'lodash'
import CreatorCalls from 'CastingCalls'

const routes = [
  {
    title: 'Talent Roster',
    route: '/influencers',
    element: <TeamTalentPrivateRoster />,
    hasNavlink: true,
    shouldShow: (user) => {
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Talent Roster',
    route: '/influencers/tags',
    element: <TagViewer />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      // return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Talent Sheets',
    route: '/talent-sheets',
    element: <TalentSheetList />,
    hasNavlink: true,
    shouldShow: (user) => {
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Collections',
    route: '/collections',
    element: <CollectionList />,
    hasNavlink: true,
    shouldShow: (user) => {
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Campaign Tracking',
    route: '/campaigns',
    element: <TalentSheetList sheetType="campaign" />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      return _.get(user, 'teamSettings.hasCampaigns')
    },
  },
  // "Friends Search" would be entirely replaced by the new "Search" site
  // {
  //   title: 'Search',
  //   route: '/social-accounts',
  //   element: <AccountSearch showFavorites={true} />,
  //   hasNavlink: true,
  //   shouldShow: (user) => {
  //     return _.get(user, 'teamSettings.hasFriendsSearch')
  //   },
  // },
  {
    title: 'Favorites',
    route: '/favorites',
    element: <SearchFavorites />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      // return _.get(user, 'teamSettings.hasFriendsSearch')
    },
  },
  {
    title: 'Messages',
    route: '/messages',
    element: <Conversations />,
    hasNavlink: false,
    shouldShow: false,
  },
  {
    title: 'Creator Calls',
    route: '/creator-calls',
    element: <CreatorCalls />,
    hasNavlink: true,
    shouldShow: (user) => {
      return _.get(user, 'teamSettings.hasCreatorCalls')
    },
  },
]

export default routes
