// import 'react-select/dist/react-select.css'
import React, { useState } from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import _ from 'lodash'
import { joinClassNames } from 'utils'
// import masterTags from 'masterTags.json'
import { useSelector } from 'react-redux'

export default function AutoSuggest({
  handleAddTagFromForm,
  handleRemoveTag,
  currentTags = [],
  allChoices,
  inputPlaceholder = 'add a tag',
  allowCreation = false,
  forceLowerCase = true,
  variant = 'default',
}) {
  const [tagInput, setTagInput] = useState(null)

  const masterTags = useSelector((state) => state.tags.masterTags)

  const suggestedTags = allChoices.map((t) => ({
    label: t + `${!masterTags.includes(t) ? '*' : ''}`,
    value: t,
  }))

  const handleInputChange = (selectedOption) => {
    if (!selectedOption?.value) return
    handleAddTagFromForm(selectedOption.value.toLowerCase())
  }

  const tagOptions = suggestedTags.filter((option) => !currentTags.includes(option.value))

  const SelectVariant = allowCreation ? Creatable : Select

  return (
    <div>
      <form
        id="tag-form"
        className=""
        onSubmit={(e) => {
          e.preventDefault()
          if (tagInput) {
            handleAddTagFromForm(tagInput.value?.toLowerCase())
            setTagInput(null)
          }
        }}
      >
        <div className="">
          <div className="">
            <SelectVariant
              options={tagOptions}
              value={tagInput}
              onChange={handleInputChange}
              placeholder={inputPlaceholder}
              isClearable={true}
              className={joinClassNames([
                variant === 'dark' ? 'text-white bg-dark-blue' : 'text-dark bg-white',
              ])}
            />
          </div>
          <div>
            <input
              className="btn btn-primary d-none btn-sm ms-md-2 ms-0 mt-2 mt-md-0"
              data-purpose="add-tag"
              type="submit"
              value="Add"
            />
          </div>
        </div>
      </form>
      {!_.isEmpty(currentTags) && (
        <div className="mt-2">
          {currentTags.map((t, i) => {
            return (
              <div
                key={`tag-${i}`}
                data-purpose="delete-tag"
                className={joinClassNames([
                  'tag selectable badge rounded-pill',
                  masterTags.includes(t) ? 'bg-info' : 'bg-secondary',
                  'text-white my-1 py-2',
                ])}
                onClick={() => {
                  handleRemoveTag(t)
                }}
              >
                {t}
                <span className="rounded-pill ms-1 p-2">x</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
