import { createReducer } from '@reduxjs/toolkit'

const initialFiltersState = {
  platform: 'instagram',
  orderBy: 'followers',
  orderDirection: 'desc',
  us_audience_share: null,
  female: null,
  followers: null,
  engagement_rate: null,
  gender_selection: null,
  subcategories: [],
  account_gender: null,
  target_gender: null,
  target_gender_share: null,
  use_advanced_filters: false,
}

const searchFiltersReducer = createReducer(initialFiltersState, (builder) => {
  builder
    .addCase('SET_PLATFORM', (state, action) => {
      state.platform = action.payload
    })
    .addCase('SET_ORDER_BY', (state, action) => {
      if (state.orderBy === action.payload) {
        state.orderDirection = state.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        state.orderBy = action.payload
        state.orderDirection = 'desc'
      }
    })
    .addCase('SET_ORDER_DIRECTION', (state, action) => {
      state.orderDirection = action.payload
    })
    .addCase('SET_SHARE', (state, action) => {
      state[action.payload.name] = action.payload.value
    })
    .addCase('SET_AGE_GROUP', (state, action) => {
      state.age_group = action.payload.age_group
      state.age_target_share = action.payload.age_target_share
    })
    .addCase('SET_GENDER', (state, action) => {
      state.target_gender = action.payload.target_gender
      state.target_gender_share = action.payload.target_gender_share
    })
    .addCase('SET_ACCOUNT_GENDER', (state, action) => {
      state.account_gender = action.payload
    })
    .addCase('CLEAR_FILTERS', (state, action) => {
      const platform = state.platform
      for (const key in state) {
        if (state.hasOwnProperty(key)) {
          state[key] = initialFiltersState[key]
        }
      }
      state.platform = platform
    })
    .addCase('SET_SUBCATEGORY', (state, action) => {
      console.log(action)
      state.subcategories = action.payload
    })
    .addCase('TOGGLE_ADVANCED_FILTERS', (state, action) => {
      state.use_advanced_filters = !state.use_advanced_filters
    })
})

export default searchFiltersReducer
