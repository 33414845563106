import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import InfluencerFilters from './components/InfluencerFilters'
import PageMenu from './PageMenu'
import TalentRosterTable from './TalentRosterTable'
import applyFilters from './applyFilters'
import { useSelector } from 'react-redux'
import ClickToCopy from 'components/ClickToCopy'
import { selectTeamSettings } from './redux/teamSlice'
import { Helmet } from 'react-helmet'

export default function TeamTalentPrivateRoster() {
  const pageLimit = 50
  const navigate = useNavigate()

  const team_influencer_ids = useSelector((state) => state.influencers.team_influencer_ids)

  const influencers = useSelector((state) => {
    return state.influencers.entities
  })

  const teamInfluencers = useMemo(
    () => _.pick(influencers, team_influencer_ids),
    [influencers, team_influencer_ids],
  )

  const socials = useSelector((state) => state.socials.entities)

  const total_influencers = useSelector((state) => state.influencers.total_influencers)
  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const [orderBy, setOrderBy] = useState(null)
  const tagged_influencers = useSelector((state) => state.tags.tagged_influencers)
  const [filteringTags, setFilteringTags] = useState([])
  const [nameFilter, setNameFilter] = useState('')

  const displayedInfluencers = useMemo(() => {
    if (_.isEmpty(influencers)) return []
    let influencersToShow = _.map(teamInfluencers, (influencer) => influencer)

    if (nameFilter.trim() !== '' || filteringTags.length > 0) {
      influencersToShow = applyFilters({
        influencers: influencersToShow,
        filteringTags,
        nameFilter,
        taggedInfluencers: tagged_influencers,
        orderBy,
      })
    } else {
      if (orderBy) {
        console.log('order by', orderBy)
        influencersToShow = _.orderBy(influencersToShow, orderBy)
      }
      influencersToShow = influencersToShow.slice((page - 1) * pageLimit, page * pageLimit)
    }
    return influencersToShow
    // setDisplayedInfluencers(influencersToShow)
  }, [filteringTags, page, orderBy, teamInfluencers, nameFilter])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  const totalPages = Math.ceil(total_influencers / pageLimit)

  const showPageMenus = totalPages > 1 && _.isEmpty(filteringTags) && _.isEmpty(nameFilter)

  let handleChangeName = (value) => {
    setNameFilter(value)
  }

  handleChangeName = _.debounce(handleChangeName, 600)

  if (_.isEmpty(influencers) || _.isEmpty(socials)) {
    return <div>Loading...</div>
  }

  return (
    <div className="m-0 p-0 bg-white">
      <Helmet>
        <title>Talent Pitch Pro - Talent Roster</title>
      </Helmet>
      <div className="container pt-3">
        <div className="mb-3" style={{ fontSize: '1.3em' }}>
          <Link
            data-purpose="public-roster-link"
            to={`/public/talent-roster/${user.selectedTeamId}`}
          >
            Public Link for Sharing Roster
          </Link>
          <ClickToCopy
            iconId={'share-profile-copy-icon'}
            textToCopy={`${window.location.origin}/public/talent-roster/${user.selectedTeamId}`}
          />
        </div>
        <InfluencerFilters
          filteringTags={filteringTags}
          setFilteringTags={setFilteringTags}
          setNameFilter={setNameFilter}
          allowHide={true}
        />
        {showPageMenus && (
          <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/influencers" />
        )}
        <p>
          <Link to="/influencers/tags">View All Tags</Link>
        </p>
      </div>

      <TalentRosterTable
        displayedInfluencers={displayedInfluencers}
        socials={socials}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        settings={teamSettings}
      />
      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/influencers" />
      )}
    </div>
  )
}
