import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Fragment, useState } from 'react'

export default function TagViewer() {
  const influencers = useSelector((state) => state.influencers.entities)
  const [error, setError] = useState(null)
  const allTags = useSelector((state) => state.tags.tags)
  const tagged_influencers = useSelector((state) => state.tags.tagged_influencers)

  const dispatch = useDispatch()

  return (
    <div>
      <div className="container mt-4">
        <h3>All Tags</h3>
        <div style={{ fontSize: '1.2em' }} className="d-flex flex-row flex-wrap mb-4">
          {allTags.map((tag, i) => {
            return (
              <div key={`tag-${i}`}>
                <div className="badge bg-primary m-2">{tag}</div>
              </div>
            )
          })}
        </div>

        <h3>Used Tags</h3>
        <div style={{ fontSize: '1.2em' }} className="d-flex flex-row flex-wrap mb-4">
          {allTags.map((tag, i) => {
            if (!tagged_influencers[tag]) return null
            return (
              <div key={`tag-${i}`}>
                <div className="badge bg-primary m-2">{tag}</div>
              </div>
            )
          })}
        </div>

        <div className="row text-center text-bold">
          <div className="col-3">
            <h4>Tag</h4>
          </div>
          <div className="col-9">
            <h4>Talent</h4>
          </div>
        </div>
        <hr />
        <>
          {allTags.map((tag, i) => {
            if (!tagged_influencers[tag]) return null

            return (
              <Fragment key={`tag-${i}`}>
                <div className="row align-items-center">
                  <div className="col-3 text-bold">
                    <div>{tag}</div>
                  </div>
                  <div className="col-9">
                    <div className="row">
                      {tagged_influencers[tag]?.map((influencer_id, i) => {
                        const influencer = influencers[influencer_id]
                        if (!influencer) {
                          return null
                        }
                        return (
                          <div key={`tagged-influencer-${i}`} className={'m-2 col-3'}>
                            <Link
                              to={`/influencers/profile/${influencer_id}`}
                              className="text-decoration-none"
                            >
                              {influencers[influencer_id].fullname}
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <hr />
              </Fragment>
            )
          })}
        </>
      </div>
    </div>
  )
}
