import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'
import masterTags from 'masterTags.json'

interface TaggedInfluencers {
  [key: string]: string[]
}

interface TagsState {
  masterTags: string[]
  tags: string[]
  tagged_influencers: TaggedInfluencers
  categories: string[]
}

interface AllTagsResponse {
  master_tags?: string[]
  tags: string[]
  tagged_influencers: TaggedInfluencers
}

export const fetchAllTags = createAsyncThunk<AllTagsResponse>('tags/fetchAll', async () => {
  const response = await axios.get<AllTagsResponse>('/api/user/team/tags')
  return response.data
})

export const fetchAllCategories = createAsyncThunk<string[]>('categories/fetchAll', async () => {
  const response = await axios.get<string[]>('/api/user/team/sheet-categories')
  return response.data
})

interface AddTagsPayload {
  tags: string[]
  influencer_id: string
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    masterTags: [...masterTags],
    tags: [...masterTags],
    tagged_influencers: {},
    categories: [],
  } as TagsState,
  reducers: {
    addTags: (state, { payload }: PayloadAction<AddTagsPayload>) => {
      state.tags = _.uniq([...state.tags, ...payload.tags])
      const newTagged = { ...state.tagged_influencers }
      for (let t of payload.tags) {
        if (!newTagged[t]) {
          newTagged[t] = [payload.influencer_id]
        } else {
          newTagged[t] = _.uniq([...newTagged[t], payload.influencer_id])
        }
      }
      state.tagged_influencers = newTagged
    },
    updateMasterTags: (state, { payload }: PayloadAction<string[]>) => {
      state.masterTags = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTags.fulfilled, (state, { payload }) => {
      state.tags = _.uniq([...state.tags, ...payload.tags])
      state.tagged_influencers = payload.tagged_influencers

      if (payload.master_tags && payload.master_tags.length > 0) {
        state.masterTags = _.uniq([...payload.master_tags])
        state.tags = _.uniq([...payload.tags, ...payload.master_tags])
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const { addTags, updateMasterTags } = tagsSlice.actions

export default tagsSlice.reducer
