import _ from 'lodash'
import { fetchSingleProfileForSheet } from './redux/talentSheetSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import LoadingContainer from './components/LoadingContainer.js'
import PublicProfilev2 from 'PublicProfiles/v2/PublicProfile_v2'
import { fetchSingleSheet } from 'redux/allSheetsSlice'
import RenderPublicRoster from 'RenderPublicRoster'
import { fetchCastingCalls, fetchResponseSheet } from 'redux/castingCallsSlice'

export default function PublicTalentSheetRoster() {
  const pageLimit = 18

  const params = useParams()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(window.location.search)
  const [talentLoading, setTalentLoading] = useState(false)
  const currentPage = Number(searchParams.get('page')) || 1
  const [currentGroup, setCurrentGroup] = useState(1)

  const singleSheetLoading = useSelector((state: any) => state.allSheets.loading)

  const loadNextGroup = () => {
    dispatch(
      fetchSingleSheet({
        hash_id: params.talentSheetId!,
        page: currentGroup + 1,
        limit: pageLimit,
        with_stats: false,
      }),
    )
      .then(() => {
        setCurrentGroup(currentGroup + 1)
        setTalentLoading(false)
      })
      .catch((e) => {
        setError('Error loading more')
        setTalentLoading(false)
      })
  }

  const navigate = useNavigate()
  const talentSheet = useSelector((state: any) => state.userSheet)
  const team = useSelector((state: any) => state.team)

  const [error, setError] = useState('')
  let influencer_ids = talentSheet ? talentSheet.influencer_ids : []
  const influencers = useSelector((state: any) => state.influencers.entities)
  const socials = useSelector((state: any) => state.socials.entities)

  let pageInfluencerIds
  let alreadyFetched: any[] = []
  if (!_.isEmpty(influencer_ids) && influencers) {
    pageInfluencerIds = influencer_ids.slice(
      (currentPage - 1) * pageLimit,
      currentPage * pageLimit,
    )
    alreadyFetched = pageInfluencerIds
      .map((iid: string) => {
        return influencers[iid]
      })
      .filter((i: any) => i)
  }

  useEffect(() => {
    if (searchParams.get('influencer_id')) {
      const talentSheetId = params.talentSheetId!
      dispatch(
        fetchSingleProfileForSheet({
          influencer_id: searchParams.get('influencer_id')!,
          salesheet_id: talentSheetId,
        }),
      )
    }
  }, [dispatch, params.talentSheetId, searchParams.get('influencer_id')])

  const userEmail = useSelector((state) => state.user.email)

  useEffect(() => {
    if (params.castingCallId) {
      dispatch(fetchCastingCalls())
      // @ts-ignore
      dispatch(fetchResponseSheet(params.castingCallId))
    }
  }, [])

  const sheetTeamSettings = useSelector((state: any) => {
    return state.team.settings
  })

  if (talentSheet && !_.isEmpty(talentSheet.error)) {
    return (
      <div style={{ paddingTop: '80px' }} className="my-3">
        <div className="logo-container mb-3">
          <img
            src="https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg"
            alt="Company Logo"
            className="company-logo"
          />
        </div>

        <h3 className="text-center">{talentSheet.error}</h3>
      </div>
    )
  }

  if (_.isEmpty(alreadyFetched)) {
    return <LoadingContainer />
  }

  const displaySettings = sheetTeamSettings || team.settings

  if (searchParams.get('influencer_id')) {
    const paramInfluencerId = searchParams.get('influencer_id')!
    let selectedProfile = _.get(influencers, paramInfluencerId)
    if (_.isEmpty(selectedProfile)) {
      return <LoadingContainer />
    }

    return (
      <PublicProfilev2
        influencerNote={talentSheet.notes[selectedProfile.id]}
        influencer={selectedProfile}
        socials={socials}
        showBackArrow={true}
        onBack={() => {
          navigate(-1)
        }}
        settings={{
          ...talentSheet.settings,
          teamLogo: talentSheet.team_image_url,
          teamName: talentSheet.team_name,
          excluded_post_ids: _.get(selectedProfile, 'team_settings.excluded_post_ids', []),
        }}
        displaySettings={displaySettings}
        talentSheetId={params.talentSheetId}
      />
    )
  }

  const total_influencers = _.get(talentSheet, 'influencer_ids').length
  const totalPages = Math.ceil(total_influencers / pageLimit)

  let displayedInfluencers = influencer_ids
    .map((iid: string) => influencers[iid])
    .filter((inf: any) => inf)

  if (_.isEmpty(displayedInfluencers)) {
    return <LoadingContainer />
  }

  return (
    <RenderPublicRoster
      talentSheet={talentSheet}
      socials={socials}
      displayedInfluencers={displayedInfluencers}
      totalPages={totalPages}
      currentGroup={currentGroup}
      pageLimit={pageLimit}
      talentLoading={talentLoading}
      setTalentLoading={setTalentLoading}
      sheetIsLoading={singleSheetLoading}
      loadNextGroup={loadNextGroup}
      displaySettings={displaySettings}
    />
  )
}
