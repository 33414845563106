const CommonFormWrapper = ({ children }) => (
  <div className="container-flex site-bottom-section bg-blue-gradient">
    <div className="container">
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-8 signin-main mt-4 p-4">{children}</div>
      </div>
    </div>
  </div>
)

export default CommonFormWrapper
