import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  fetchSingleInfluencer,
  fetchPublicInfluencerProfile,
  fetchPublicInfluencers,
} from 'redux/influencerSlice'
import { fetchAllCollections, fetchCollection } from 'redux/collectionsSlice'
import _ from 'lodash'
import axios from 'axios'
import { CollectionSheet, Influencer, Sheet } from 'types'
import { fetchVisitorAnalytics } from './analyticsSlice'

export const fetchSheet = createAsyncThunk('usersheet/fetchOne', async (hash_id: string) => {
  const response = await axios.get(`/api/talent-sheets/${hash_id}`)
  return response.data
})

interface FetchByIdsParams {
  hash_ids: string[]
}

export const fetchByIds = createAsyncThunk(
  'usersheet/fetchByIds',
  async ({ hash_ids }: FetchByIdsParams) => {
    const params = {
      hash_ids,
    }
    const response = await axios.get(`/api/talent-sheets`, { params })
    return response.data
  },
)

interface FetchSheetsTypeParams {
  sheet_type?: string
  page?: number
  limit?: number
  only_mine?: boolean
  title_filter?: string
}

export const fetchSheetsType = createAsyncThunk(
  'allSheets/fetchType',
  async ({
    sheet_type = 'talent',
    page = 1,
    limit = 80,
    only_mine = false,
    title_filter,
  }: FetchSheetsTypeParams) => {
    const params: {
      sheet_type: string
      page: number
      limit: number
      only_mine?: boolean
      title_filter?: string
    } = {
      sheet_type,
      page,
      limit,
      only_mine: only_mine || undefined,
      title_filter: !_.isEmpty(title_filter) ? title_filter : undefined,
    }

    const response = await axios.get('/api/talent-sheets', {
      params,
    })

    return _.get(response, 'data')
  },
)

interface FetchSheetsForCollectionParams {
  title_filter?: string
}

export const fetchSheetsForCollection = createAsyncThunk(
  'allSheets/fetchForCollection',
  async ({ title_filter }: FetchSheetsForCollectionParams = {}) => {
    const params = {
      title_filter,
      for_collection: true,
    }
    const response = await axios.get('/api/talent-sheets', {
      params,
    })
    return _.get(response, 'data')
  },
)

export const fetchBasicInfo = createAsyncThunk('usersheet/fetchBasicInfo', async () => {
  const response = await axios.get(`/api/talent-sheets/basic`)
  return response.data
})

interface FetchSingleSheetParams {
  hash_id: string
  page?: number
  limit?: number
  with_stats?: boolean
}

export const fetchSingleSheet = createAsyncThunk(
  'allSheets/fetchSingleSheet',
  async ({ hash_id, page = 1, limit = 15, with_stats = true }: FetchSingleSheetParams) => {
    const loadSheet = async () => {
      try {
        const params = {
          page,
          limit,
          with_stats,
        }
        let url = `/api/public/talent-sheets/${hash_id}`

        const { data } = await axios.get(url, { params })
        if (_.isEmpty(data)) {
          return {
            error: 'Something went wrong! No Talent Sheet was found',
          }
        }
        return data
      } catch (e) {
        return {
          id: hash_id,
          error: 'Something went wrong! No Talent Sheet was found',
        }
      }
    }
    return loadSheet()
  },
)

interface FetchIndividualProfileForSheetParams {
  salesheet_id: string
  influencer_id: string
}

export const fetchIndividualProfileForSheet = createAsyncThunk(
  'allSheets/fetchIndividualProfile',
  async ({ salesheet_id, influencer_id }: FetchIndividualProfileForSheetParams) => {
    const params = {
      salesheet_id,
      influencer_id,
    }
    const response = await axios.get(`/api/public/talent-sheet-profile`, { params })
    return response.data
  },
)

const sheetsAdapter = createEntityAdapter<Partial<Sheet>>()

interface AllSheetsState {
  loading: boolean
  total_sheets: null | number
  own_sheets_count: null | number
  error: null | string
  ordered_sheets: Sheet[]
  own_sheets: Sheet[]
  sheet_count: number | null
}

const initialState: AllSheetsState = {
  loading: false,
  total_sheets: null,
  own_sheets_count: null,
  error: null,
  ordered_sheets: [],
  own_sheets: [],
  sheet_count: null,
}

export const allSheetsSlice = createSlice({
  name: 'allSheets',
  initialState: sheetsAdapter.getInitialState(initialState),
  reducers: {
    removeSheet: (state, { payload }: PayloadAction<string>) => {
      console.log(`remove ${payload}`)
      sheetsAdapter.removeOne(state, payload)
    },
    updateSheetDescription: (
      state,
      { payload }: PayloadAction<{ id: string; description: string }>,
    ) => {
      const sheet = state.entities[payload.id]
      if (sheet) {
        sheet.description = payload.description
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSheet.fulfilled, (state, { payload }) => {
        sheetsAdapter.upsertOne(state, payload[0])
      })
      .addCase(fetchSheetsType.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSheetsType.fulfilled, (state, { payload }) => {
        state.loading = false
        if (_.isEmpty(payload.sheets)) return
        // const { extra } = payload
        // const firstIndex =
        //   extra.page && extra.limit
        //     ? extra.page === 1
        //       ? 0
        //       : (extra.page - 1) * extra.limit
        //     : undefined

        // const indexedSheets = payload.sheets.map((sheet: Sheet, i: number) => {
        //   return {
        //     ...sheet,
        //     index: firstIndex !== undefined ? firstIndex + i : undefined,
        //   }
        // })

        sheetsAdapter.upsertMany(state, payload.sheets)
      })
      .addCase(fetchVisitorAnalytics.fulfilled, (state, { payload }) => {
        const { sheets } = payload
        if (_.isEmpty(sheets)) return
        sheetsAdapter.upsertMany(state, sheets)
      })
      .addCase(fetchByIds.fulfilled, (state, { payload }) => {
        const toUpsert = payload.map((sheet: Sheet) => {
          return {
            ...sheet,
          }
        })
        sheetsAdapter.upsertMany(state, toUpsert)
        state.loading = false
      })
      .addCase(fetchSingleSheet.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchPublicInfluencers.fulfilled, (state, { payload }) => {
        const pseudoSheetId = `team-roster-${payload.teamDetails.id}`
        let pseudoSheet
        if (state.entities[pseudoSheetId]) {
          pseudoSheet = state.entities[pseudoSheetId]
          const newInfluencerIds = _.uniq([
            // @ts-ignore
            ...pseudoSheet.influencer_ids,
            ...payload.influencers.map((influencer: Influencer) => influencer.id),
          ])

          sheetsAdapter.upsertOne(state, {
            id: pseudoSheetId,
            team_id: payload.teamDetails.id,
            influencer_ids: newInfluencerIds,
          })
          return
        }

        pseudoSheet = {
          id: `team-roster-${payload.teamDetails.id}`,
          team_id: payload.teamDetails.id,
          influencer_ids: payload.influencers.map((influencer: Influencer) => influencer.id),
        }
        sheetsAdapter.upsertOne(state, pseudoSheet)
      })
      .addCase(fetchSingleSheet.fulfilled, (state, { payload }) => {
        if (!payload.error) {
          const newSheet = { ...payload.sheet }
          newSheet.team = {
            name: payload.sheet.team_name,
            image_url: payload.sheet.team_image_url,
          }

          sheetsAdapter.upsertOne(state, newSheet)
        } else {
          // sheetsAdapter.upsertOne(state, { id: payload.id, error: payload.error })
          state.error = payload.error
        }
        state.loading = false
      })
      .addCase(fetchBasicInfo.fulfilled, (state, { payload }) => {
        state.sheet_count = payload.sheet_count
        state.ordered_sheets = payload.ordered_sheets
        state.own_sheets = payload.user_sheets_ordered
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.loading = false
        if (_.isEmpty(payload.sheets)) return

        payload.sheets.forEach((sheet: CollectionSheet) => {
          const existingSheet = state.entities[sheet.id]
          if (existingSheet) {
            state.entities[sheet.id] = {
              ...existingSheet,
              influencer_ids: sheet.influencer_ids,
            }
          } else {
            sheetsAdapter.upsertOne(state, sheet)
          }
        })
      })
      .addCase(fetchSheetsForCollection.fulfilled, (state, { payload }) => {
        if (_.isEmpty(payload.sheets)) return

        for (let sheet of payload.sheets) {
          const existingSheet = state.entities[sheet.id]
          if (existingSheet) {
            state.entities[sheet.id] = {
              ...existingSheet,
              title: sheet.title,
              description: sheet.description,
              influencer_ids: sheet.influencer_ids,
            }
          } else {
            sheetsAdapter.upsertOne(state, sheet)
          }
        }

        state.loading = false
      })
      .addCase(fetchAllCollections.fulfilled, (state, { payload }) => {
        state.loading = false
        if (_.isEmpty(payload.sheets)) return

        for (let sheet of payload.sheets) {
          const existingSheet = state.entities[sheet.id]
          if (existingSheet) {
            state.entities[sheet.id] = {
              ...existingSheet,
              title: sheet.title,
              description: sheet.description,
              influencer_ids: sheet.influencer_ids,
            }
          } else {
            sheetsAdapter.upsertOne(state, sheet)
          }
        }
      })
      .addCase(fetchSingleInfluencer.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSingleInfluencer.fulfilled, (state, { payload }) => {
        state.loading = false
        if (payload.error) return
        sheetsAdapter.upsertMany(state, payload.sheets)
      })
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        if (!payload.sheet) return
        sheetsAdapter.setOne(state, payload.sheet)
      })
  },
})

export const getSheetsForPage = (
  sheets: Sheet[],
  totalSheets: number,
  limit: number,
  page: number,
) => {
  const indexArray: number[] = []
  const firstSheetIndex = totalSheets - (page - 1) * limit
  for (let i = 0; i <= limit; i++) {
    indexArray.push(firstSheetIndex - i)
  }
  return _.filter(sheets, (sheet) => sheet.sheet_index && indexArray.includes(sheet.sheet_index))
}

export const { removeSheet, updateSheetDescription } = allSheetsSlice.actions

export default allSheetsSlice.reducer
