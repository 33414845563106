import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAnalytics, fetchVisitorAnalytics, getAnalyticsKey } from 'redux/analyticsSlice'
import { useEffect, useState } from 'react'

import _ from 'lodash'
import axios from 'axios'
import { useForm, useWatch } from 'react-hook-form'
import Table from 'react-bootstrap/Table'
import LoadingContainer from './components/LoadingContainer'
import { Collapse } from 'react-bootstrap'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs'

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <>
      <div className="selectable d-flex align-items-center" onClick={toggleOpen}>
        {isOpen ? <BsCaretDownFill /> : <BsCaretRightFill />}
        <h2 className="mb-0 ms-2">{title}</h2>
      </div>
      <Collapse in={isOpen}>
        <div>{children}</div>
      </Collapse>
      <hr />
    </>
  )
}

export default function SheetAnalytics() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      sinceDays: '30',
      onlyUserSheets: false,
    },
  })

  const dispatch = useDispatch()

  const currentAnalytics = useSelector((state) => {
    const formValues = getValues()
    return state.analytics[getAnalyticsKey(formValues)] || {}
  })

  const recentVisits = useSelector((state) => state.analytics.recentVisits)
  const sheets = useSelector((state) => state.allSheets.entities)

  const analyticsLoading = useSelector((state) => state.analytics.loading)

  // const [isLoading, setIsLoading] = useState(false)

  const daysWatch = useWatch({
    control,
    name: 'sinceDays',
  })

  const onlyUserSheetsWatch = useWatch({
    control,
    name: 'onlyUserSheets',
  })

  useEffect(() => {
    const formValues = getValues()
    dispatch(fetchAnalytics(formValues))
  }, [daysWatch, onlyUserSheetsWatch])

  useEffect(() => {
    dispatch(fetchVisitorAnalytics())
  }, [])

  const onSubmit = async (data) => {
    const formValues = getValues()
    dispatch(fetchAnalytics(formValues))
  }

  const [analytics, setAnalytics] = useState({})
  const [startDate, setStartDate] = useState(null)

  return (
    <div className="container">
      <h1 className="my-3">Roster Visits and Profile Clicks</h1>

      <form
        className="w-50 mb-3"
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
        }}
      >
        <fieldset className="border p-3 rounded">
          <legend className="w-auto px-2">Time Range</legend>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="all-time"
              value="all"
              {...register('sinceDays')}
            />
            <label className="form-check-label" htmlFor="all-time">
              All-time
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="7-days"
              value="7"
              {...register('sinceDays')}
            />
            <label className="form-check-label" htmlFor="7-days">
              7 days
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="30-days"
              value="30"
              {...register('sinceDays')}
            />
            <label className="form-check-label" htmlFor="30-days">
              30 days
            </label>
          </div>
        </fieldset>
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="onlyUserSheets"
            {...register('onlyUserSheets')}
          />
          <label className="form-check-label" htmlFor="onlyUserSheets">
            Only show sheets created by me
          </label>
        </div>

        <button className="btn btn-primary mt-3 d-none" type="submit">
          Submit
        </button>
      </form>
      <hr />

      {_.isEmpty(currentAnalytics) || analyticsLoading ? (
        <LoadingContainer />
      ) : (
        <>
          {currentAnalytics.views && (
            <>
              <CollapsibleSection title="Most-Viewed Sheets">
                <p>(Click title for details)</p>
                <Table striped bordered responsive className="mt-4 w-100">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th
                        scope="col"
                        className="selectable"
                        onClick={() => {
                          const sorted = _.sortBy(analytics.views, 'views').reverse()
                          setAnalytics({ ...analytics, views: sorted })
                        }}
                      >
                        Roster Visits
                      </th>
                      <th
                        className="selectable"
                        onClick={() => {
                          const sorted = _.sortBy(analytics.views, 'total_clicks').reverse()
                          setAnalytics({ ...analytics, views: sorted })
                        }}
                      >
                        Profile Clicks
                      </th>
                      <th data-tip data-for="key-definition" scope="col">
                        Link
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAnalytics.views &&
                      currentAnalytics.views.map((row) => {
                        return (
                          <tr key={`team-${row.sheet_id}`}>
                            <td>
                              <Link to={`/talent-sheets/statistics/${row.sheet_id}`}>
                                {row.sheet_title}
                              </Link>
                            </td>
                            <td>{row.views}</td>
                            <td>{row.total_clicks}</td>
                            <td style={{ width: '15%' }}>
                              <Link to={`/talent-sheet/${row.sheet_id}`}>View</Link>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </CollapsibleSection>
            </>
          )}
          {currentAnalytics.clicks && (
            <>
              <CollapsibleSection title="Most-Clicked Profiles">
                <Table striped bordered responsive className="mt-4 w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sheet Title</th>
                      <th>Talent Name</th>
                      <th scope="col">Clicks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAnalytics.clicks &&
                      currentAnalytics.clicks.map((row, i) => {
                        return (
                          <tr key={`click-${i}-${row.influencer_id}`}>
                            <td>{row.sheet_title}</td>
                            <td>{row.fullname}</td>
                            <td>{row.clicks}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </CollapsibleSection>
            </>
          )}
          {recentVisits.length > 0 && (
            <>
              <CollapsibleSection title="Visitors">
                <Table striped bordered responsive className="mt-4 w-100">
                  <thead>
                    <tr>
                      <th>email</th>
                      <th scope="col">Sheets Visited</th>
                      <th>Last Visit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentVisits.map((rv, i) => {
                      const sheet_ids = rv.salesheet_ids
                      const sheet_titles = sheet_ids.map((id) => sheets[id]?.title)
                      return (
                        <tr key={`visitor-${i}`}>
                          <td>{rv.email}</td>
                          <td>{sheet_titles.join(', ')}</td>
                          <td>{new Date(rv.most_recent_visit).toLocaleDateString()}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </CollapsibleSection>
            </>
          )}
        </>
      )}
    </div>
  )
}
