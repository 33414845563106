import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { fetchCollection } from './collectionsSlice'
import {
  fetchIndividualProfileForSheet,
  fetchSheetsType,
  fetchSingleSheet,
} from './allSheetsSlice'
import { fetchOneUserSheet } from './userSheetSlice'
import { fetchSingleProfileForSheet } from './talentSheetSlice'

import _ from 'lodash'
import axios from 'axios'
import { fetchFavoriteInfluencers } from './userSlice'
import { RootState } from 'store'
import { Influencer, Social, Sheet } from 'types'

interface SheetNote {
  [key: string]: string
}

interface InfluencerState {
  entities: Record<string, Influencer>
  ids: string[]
  teamName: string | null
  loading: 'idle' | 'pending'
  total_influencers: number | null
  teamSettings: Record<string, string>
  team_influencer_ids: string[]
}

const removeEmptyKeys = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    if (!_.isEmpty(obj[key])) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export const fetchInfluencers = createAsyncThunk('influencers/fetchAll', async () => {
  const response = await axios.get('/api/user/influencers')
  return _.get(response, 'data')
})

export const fetchInfluencersByIds = createAsyncThunk(
  'influencers/fetchByIds',
  async (ids: string[]) => {
    const params = {
      influencer_ids: ids,
    }

    const response = await axios.get('/api/user/influencers', { params })
    return _.get(response, 'data')
  },
)

interface FetchPublicInfluencersPayload {
  team_id: string
}

export const fetchPublicInfluencers = createAsyncThunk(
  'influencers/fetchPublic',
  async ({ team_id }: FetchPublicInfluencersPayload) => {
    const response = await axios.get(`/api/team/roster/${team_id}`)
    return response.data
  },
)

interface FetchPublicInfluencerProfilePayload {
  influencer_id: string
  team_id?: string
}

export const fetchPublicInfluencerProfile = createAsyncThunk(
  'influencers/fetchPublicInfluencerProfile',
  async ({ influencer_id, team_id }: FetchPublicInfluencerProfilePayload) => {
    let url = `/api/public/profile/${influencer_id}`

    if (team_id) {
      url += `/team/${team_id}`
    }
    const response = await axios.get(url)
    return response.data
  },
)

interface FetchSingleInfluencerPayload {
  influencer_id: string
}

export const fetchSingleInfluencer = createAsyncThunk(
  'influencers/fetchOne',
  async ({ influencer_id }: FetchSingleInfluencerPayload) => {
    try {
      const response = await axios.get(`/api/influencers/profiles/${influencer_id}`)
      return response.data
    } catch (e) {
      return {
        influencer_id,
        error: true,
        data: _.get(e, 'response.data'),
      }
    }
  },
)

const influencersAdapter = createEntityAdapter<Influencer>({
  selectId: (inf) => {
    return inf.id
  },
})

export const influencerSlice = createSlice({
  name: 'influencers',
  initialState: influencersAdapter.getInitialState<InfluencerState>({
    entities: {},
    ids: [],
    teamName: null,
    loading: 'idle',
    total_influencers: null,
    teamSettings: {},
    team_influencer_ids: [],
  }),
  reducers: {
    setInfluencers: (state, { payload }: PayloadAction<Influencer[]>) => {
      influencersAdapter.upsertMany(state, payload)
    },
    setTags: (state, { payload }: PayloadAction<{ influencer: string; tags: string[] }>) => {
      state.entities[payload.influencer].tags = payload.tags
    },
    setTalentSheetReaction: (
      state,
      {
        payload,
      }: PayloadAction<{
        influencer_id: string
        updates: Record<string, string>
        sheet_id: string
      }>,
    ) => {
      const { influencer_id, updates, sheet_id } = payload
      const influencer = state.entities[influencer_id]
      if (influencer.sheet_notes && influencer.sheet_notes[sheet_id]) {
        const sheetNotes = influencer.sheet_notes[sheet_id]
        state.entities[influencer_id].sheet_notes[sheet_id] = {
          ...(typeof sheetNotes === 'object' ? sheetNotes : {}),
          ...updates,
        }
      }
    },
    setInfluencerTeamSettings: (
      state,
      {
        payload,
      }: PayloadAction<{
        influencer_id: string
        updates: Record<string, string>
      }>,
    ) => {
      const { influencer_id, updates } = payload
      let newSettings = {
        ...state.entities[influencer_id].team_settings,
        ...updates,
      }
      newSettings = removeEmptyKeys(newSettings)
      state.entities[influencer_id].team_settings = newSettings
    },
    setSheetNotes: (
      state,
      {
        payload,
      }: PayloadAction<{
        sheet_id: string
        influencer_id: string
        update: Record<string, string>
      }>,
    ) => {
      influencersAdapter.updateOne(state, {
        id: payload.influencer_id,
        changes: {
          sheet_notes: {
            [payload.sheet_id]: {
              ...payload.update,
            },
          },
        },
      })
    },
    removeInfluencerById: (state, { payload }: PayloadAction<string>) => {
      influencersAdapter.removeOne(state, payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencers.fulfilled, (state, { payload }) => {
        if (payload.influencers && payload.influencers.length > 0) {
          influencersAdapter.upsertMany(state, payload.influencers)
          state.total_influencers = payload.total_influencers
          state.team_influencer_ids = payload.influencers.map((inf: Influencer) => inf.id)
        }
      })
      .addCase(fetchInfluencersByIds.fulfilled, (state, { payload }) => {
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      .addCase(fetchSingleInfluencer.pending, (state, { payload }) => {
        state.loading = 'pending'
      })
      .addCase(fetchSingleInfluencer.fulfilled, (state, { payload }) => {
        if (!payload.error) {
          const influencer = {
            ...payload.influencers[0],
            salesheet_ids: payload.sheets.map((sheet: any) => sheet.id),
          }
          influencersAdapter.upsertOne(state, influencer)
        } else {
          console.log('error fetching influencer', payload)
          // influencersAdapter.upsertOne(state, {
          //   id: payload.influencer_id,
          //   error: true,
          // })
        }

        state.loading = 'idle'
      })
      .addCase(fetchFavoriteInfluencers.fulfilled, (state, { payload }) => {
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      // .addCase(fetchCollection.fulfilled, (state, { payload }) => {
      //   influencersAdapter.upsertMany(state, payload.influencers)
      // })
      .addCase(fetchSheetsType.fulfilled, (state, { payload }) => {
        if (_.isEmpty(payload.influencers)) return
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      .addCase(fetchIndividualProfileForSheet.fulfilled, (state, { payload }) => {
        if (!payload.influencer) return
        influencersAdapter.upsertOne(state, payload.influencer)
      })
      .addCase(fetchSingleProfileForSheet.fulfilled, (state, { payload }) => {
        if (!payload.influencer) return
        influencersAdapter.upsertOne(state, payload.influencer)
      })
      .addCase(fetchSingleSheet.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      .addCase(fetchPublicInfluencers.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        state.teamName = payload.team
        state.teamSettings = payload.team_settings
        influencersAdapter.upsertMany(state, payload.influencers)
      })
      .addCase(fetchOneUserSheet.fulfilled, (state, { payload }) => {
        if (!payload.influencers) return
        influencersAdapter.upsertMany(state, payload.influencers)
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setInfluencers,
  setTags,
  setTalentSheetReaction,
  setInfluencerTeamSettings,
  setSheetNotes,
  removeInfluencerById,
} = influencerSlice.actions

export default influencerSlice.reducer
