import _, { map } from 'lodash'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useEffect, useRef, useState, useMemo } from 'react'

import LoadingContainer from './components/LoadingContainer'
import { Rings } from 'react-loader-spinner'
import TalentSheetBranding from './TalentSheetBranding'
import {
  getContrastColor,
  getContrastingTextForBackground,
  joinClassNames,
  scaleColor,
  getColorHex,
} from './utils'
import { useInView } from 'react-intersection-observer'
import { Talent } from 'helpers/Talent'
import mixpanel from './mixpanelBrowser'
import { sendEvent } from 'analytics'
import Color from 'color'
import config from './config'
import ContactInfoModal from 'components/ContactInfoModal'
import { useSelector, useDispatch } from 'react-redux'
import { setModalShow } from 'redux/modalsSlice'
import withRegistrationPopup from 'components/withRegistrationPopup'
import { Helmet } from 'react-helmet'
import TalentSheetRosterItemFullCover from 'TalentSheetRosterItemFullCover'
import TalentSheetRosterItemCircular from 'TalentSheetRosterItemCircular'

const RenderPublicRoster = ({
  talentSheet,
  socials,
  displayedInfluencers,
  totalPages = 1,
  currentGroup,
  pageLimit,
  talentLoading,
  setTalentLoading,
  sheetIsLoading,
  loadNextGroup,
  source = 'Talent Sheet',
  displaySettings = {
    displayAccentColor: 'shine-gold',
  },
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !talentLoading) {
        setTalentLoading(true)
        loadNextGroup()
      }
    },
    initialInView: true,
  })

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-white')

  const dispatch = useDispatch()
  const showContactInfo = useSelector((state) => {
    return state.modals.contactInfo === true
  })

  const RosterItem = useMemo(() => {
    if (displaySettings.displayUseRosterFullPhoto) return TalentSheetRosterItemFullCover
    if (displaySettings.useRosterFullPhoto) return TalentSheetRosterItemFullCover
    return TalentSheetRosterItemCircular
  }, [displaySettings])

  const backgroundColorStyle = useMemo(() => {
    let bgColorHex
    if (displaySettings.displayRosterBackgroundColor) {
      bgColorHex = getColorHex(displaySettings.displayRosterBackgroundColor)
      return { backgroundColor: bgColorHex }
    }

    bgColorHex = getColorHex(displaySettings.displayAccentColor)
    return { backgroundColor: scaleColor(bgColorHex, 92) }
  }, [displaySettings])

  useEffect(() => {
    // Use bgColorHex from backgroundColorStyle, falling back to 'white' if not set.
    const bgColorHex = backgroundColorStyle.backgroundColor || 'white'
    const contrastingTextColor = getContrastingTextForBackground(bgColorHex)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [backgroundColorStyle])

  const pageTitle = useMemo(() => {
    if (talentSheet?.description) return `${talentSheet.description} - Talent Pitch Pro`
    if (displaySettings?.name) return `Talent Sheet from ${displaySettings.name}`
    return `Talent Pitch Pro`
  }, [talentSheet])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <TalentSheetBranding displaySettings={displaySettings} talentSheet={talentSheet} />
      <div
        ref={mainContainerRef}
        className={joinClassNames([
          typeof backgroundColorStyle === 'string' ? backgroundColorStyle : null,
          'public-roster-container',
        ])}
        style={backgroundColorStyle}
      >
        <div className="container">
          <h4
            className={joinClassNames([
              `text-center`,
              textColorClass,
              `mb-3 py-3`,
              'd-print-none',
            ])}
          >
            Click creator photo for analytics
          </h4>
          <div
            className={joinClassNames([
              `row`,
              displaySettings.displayUseRosterFullPhoto ? 'gx-5' : null,
            ])}
          >
            {_.isEmpty(displayedInfluencers) && (
              <div className="d-flex justify-content-center">
                <Rings
                  height="80"
                  width="80"
                  color="#5863f8ff"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            )}
            {map(displayedInfluencers, (influencer, i) => {
              const talent = new Talent(influencer, socials)
              const profile = {
                ...influencer,
              }
              profile.socials = talent.getSocials().allSocials

              return (
                <RosterItem
                  key={`influencer-${influencer.id}`}
                  specialText={_.get(talentSheet, ['notes', influencer.id], null)}
                  influencer={influencer}
                  handleClick={() => {
                    mixpanel.track(`${source} - Clicked Influencer`, {
                      influencer_id: influencer.id,
                      talent_sheet_id: params.talentSheetId,
                    })
                    sendEvent('sheet_click_influencer', {
                      influencer_id: influencer.id,
                      sheet_id: params.talentSheetId,
                      team_id: params.teamId,
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                    navigate(`?influencer_id=${influencer.id}`)
                  }}
                  profile={profile}
                  displaySettings={_.get(talentSheet, 'settings', {})}
                  source={source}
                />
              )
            })}
          </div>
        </div>
        {totalPages > 1 && currentGroup * pageLimit <= talentSheet.influencer_ids.length && (
          <div ref={ref} className="d-flex justify-content-center w-100 pb-4">
            <button className="btn btn-primary" onClick={loadNextGroup}>
              {talentLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        {sheetIsLoading && <LoadingContainer />}
        <ContactInfoModal
          showModal={showContactInfo}
          handleClose={() => dispatch(setModalShow({ modal: 'contactInfo', show: false }))}
          logoUrl={talentSheet?.team_image_url}
          email={talentSheet?.team_contact_email}
          phoneNumber={talentSheet?.team_phone}
          homepage={talentSheet?.team_homepage}
          bio={talentSheet?.team_bio}
        />
      </div>
    </>
  )
}

export default withRegistrationPopup(RenderPublicRoster)
