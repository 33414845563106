import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import _ from 'lodash'

interface Filter {
  only_ids?: number[]
  [key: string]: any
}

interface Influencer {
  id: number
  subcategory_ids: number[]
  [key: string]: any
}

interface Social {
  id?: string // Make id optional
  [key: string]: any
}

interface SearchState {
  socials_influencer_lookup: Record<number, number[]>
  search_result: number[]
  instagram: {
    filtered_results: any[]
    orderings: Record<string, any>
    entities: Record<string, any>
  }
  youtube: {
    filtered_results: any[]
    orderings: Record<string, any>
    entities: Record<string, any>
  }
  tiktok: {
    filtered_results: any[]
    orderings: Record<string, any>
    entities: Record<string, any>
  }
  influencers: ReturnType<typeof influencersAdapter.getInitialState>
  socials: ReturnType<typeof socialsAdapter.getInitialState>
  status: 'idle' | 'loading'
  processedResults: any[]
  hasRepeatedAccounts: boolean
}

interface SearchResult {
  socials_influencer_lookup: Record<number, number[]>
  search_result: number[]
  influencers: Influencer[]
  socials: Social[]
  [key: string]: any
}

export const fetchFilteredSearchInfluencers = createAsyncThunk<SearchResult, Filter>(
  '/search/filtered',
  async (filters: Filter) => {
    if (filters.only_ids && filters.only_ids.length === 0) {
      return null as any
    }

    const params = {
      ...filters,
    }
    if (!filters.only_ids || filters.only_ids.length === 0) {
      delete params.only_ids
    }

    const result: AxiosResponse<SearchResult> = await axios.get(
      `/api/search/influencers/filtered`,
      {
        params: {
          ...filters,
        },
      },
    )

    return result.data
  },
)

const influencersAdapter = createEntityAdapter<Influencer>()
const socialsAdapter = createEntityAdapter<Social>()

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    socials_influencer_lookup: {},
    search_result: [],
    instagram: {
      filtered_results: [],
      orderings: {},
      entities: {},
    },
    youtube: {
      filtered_results: [],
      orderings: {},
      entities: {},
    },
    tiktok: {
      filtered_results: [],
      orderings: {},
      entities: {},
    },
    influencers: influencersAdapter.getInitialState(),
    socials: socialsAdapter.getInitialState(),
    status: 'idle' as 'idle' | 'loading',
    processedResults: [],
    hasRepeatedAccounts: false,
  } as SearchState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(
      fetchFilteredSearchInfluencers.pending,
      (state, action: PayloadAction<any>) => {
        state.status = 'loading'
      },
    )
    builder.addCase(
      fetchFilteredSearchInfluencers.fulfilled,
      (state, action: PayloadAction<SearchResult | null>) => {
        const payload = action.payload
        if (!payload) {
          state.status = 'idle'
          return
        }
        state.socials_influencer_lookup = payload.socials_influencer_lookup
        state.search_result = payload.search_result
        influencersAdapter.upsertMany(state.influencers, payload.influencers)
        socialsAdapter.upsertMany(state.socials, payload.socials)
        state.status = 'idle'

        const getProcessedResults = (payload: SearchResult) => {
          const socials = _.keyBy(payload.socials, 'id')
          const lookup = payload.socials_influencer_lookup
          const search_result = payload.search_result
          const influencers = payload.influencers
          let table_rows: any[] = []

          for (let social_id of search_result) {
            let item = { ...socials[social_id] }

            item.social_account_id = social_id
            item = _.omit(item, 'id')

            const matching_influencers = lookup[social_id]

            if (!matching_influencers) continue

            for (let influencer_id of matching_influencers) {
              const influencer = influencers[influencer_id]

              table_rows.push({
                ...item,
                id: influencer_id,
                subcategory_ids: _.get(influencer, 'subcategory_ids', []),
              })
            }
          }
          return table_rows
        }

        const processedResults = getProcessedResults(payload)

        state.processedResults = processedResults

        state.hasRepeatedAccounts = processedResults.length > payload.socials.length
      },
    )
  },
})

export default searchSlice.reducer
