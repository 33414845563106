import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchSheetsType, removeSheet } from './redux/allSheetsSlice'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'react-bootstrap/Button'
import { Dna } from 'react-loader-spinner'
import { FaTrash } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal'
import PageMenu from './PageMenu'
import _ from 'lodash'
import axios from 'axios'
import date from 'date-and-time'
import { resetSheet } from './redux/userSheetSlice'
import useLocalStorage from 'hooks/useLocalStorage'
import { selectTeamSettings } from './redux/teamSlice'
import { Helmet } from 'react-helmet'
import useDebounce from 'hooks/useDebounce'

function ConfirmModal({ show, handleClose }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to delete this sheet? There is no 'Undo' button for this.
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="cancel-delete"
            variant="secondary"
            onClick={() => {
              handleClose(false)
            }}
          >
            Cancel
          </Button>
          <Button
            data-purpose="confirm-delete"
            onClick={() => {
              handleClose(true)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const filterByTitle = (sheet, titleSearchTerm) => {
  const search = titleSearchTerm.toLowerCase()
  const result = sheet?.title && sheet.title.toLowerCase().includes(search)
  return result
}

export default function TalentSheetList({ sheetType = 'talent' }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [sheetToDelete, setSheetToDelete] = useState(null)
  const [onlyShowMine, setOnlyShowMine] = useLocalStorage('onlyShowMine', false)
  const [sheetTitleFilterInput, setSheetTitleFilterInput] = useState('')

  const [showFullInfluencerList, setShowFullInfluencerList] = useState({})

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const pageBaseUrl = '/talent-sheets'
  const pageLimit = 60

  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)

  const influencers = useSelector((state) => state.influencers.entities)

  const displaySettings = useSelector((state) => {
    return {
      light: _.get(state.user, 'teamSettings.pageTheme') === 'light',
    }
  })

  const debouncedSearchTerm = useDebounce(sheetTitleFilterInput, 1200)

  useEffect(() => {
    let action = {
      sheet_type: sheetType,
      page: page,
      limit: pageLimit,
      only_mine: onlyShowMine,
    }

    if (debouncedSearchTerm.trim().length > 2) {
      action.title_filter = debouncedSearchTerm
    } else {
      action.page = page
      action.limit = pageLimit
    }

    dispatch(fetchSheetsType(action))
  }, [dispatch, sheetType, page, pageLimit, onlyShowMine, debouncedSearchTerm])

  const sheetsLoading = useSelector((state) => state.allSheets.loading)

  let displaySheets = useSelector((state) => {
    const { entities, own_sheets, ordered_sheets } = state.allSheets
    let filtered_sheets
    if (debouncedSearchTerm !== '' && debouncedSearchTerm.length > 2) {
      filtered_sheets = _.filter(entities, (sheet) => {
        return filterByTitle(sheet, debouncedSearchTerm)
      })
      if (onlyShowMine) {
        filtered_sheets = filtered_sheets.filter((s) => s.creator_user_id === user.account_id)
      }

      return filtered_sheets.filter((s) => s)
    }

    let theSheets = onlyShowMine ? own_sheets : ordered_sheets
    theSheets = theSheets.slice((page - 1) * pageLimit, page * pageLimit)

    filtered_sheets = theSheets.map((id) => entities[id])

    return filtered_sheets.filter((s) => s)
  })

  const totalPages = useSelector((state) => {
    if (!state.allSheets.sheet_count) {
      return 1
    }
    let sheet_count = onlyShowMine
      ? state.allSheets.own_sheets.length
      : state.allSheets.sheet_count

    return Math.ceil(sheet_count / pageLimit)
  })

  const showPageMenu = debouncedSearchTerm === '' && totalPages > 1

  const handleCloseDeleteModal = async (confirm) => {
    setShow(false)
    if (confirm) {
      const deleteResponse = await axios.post('/api/user/talent-sheets/delete', {
        sheet_id: sheetToDelete,
      })
      if (deleteResponse.data.success) {
        dispatch(removeSheet(sheetToDelete))
      }
    }
    setSheetToDelete(null)
  }

  const tableColumns = [
    {
      th: (
        <th style={{ width: '40%' }} className="text-center" scope="col">
          TITLE
        </th>
      ),
      td: (sheet, settings = {}) => {
        return (
          <td>
            <Link
              className={`text-${settings.light ? 'black' : 'white'}`}
              to={`/${sheetType === 'campaign' ? 'campaign/view' : 'talent-sheet'}/${sheet.id}`}
            >
              {sheet.title}
            </Link>
          </td>
        )
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          TALENT
        </th>
      ),
      td: (sheet) => {
        let sheetInfluencers = _.get(sheet, 'influencer_ids', []).map((id) => {
          return influencers[id] || {}
        })
        const longList = sheetInfluencers.length > 9
        sheetInfluencers =
          longList && !showFullInfluencerList[sheet.id]
            ? sheetInfluencers.slice(0, 6)
            : sheetInfluencers

        return (
          <td>
            <ul>
              {sheetInfluencers.map((inf, i) => {
                return <li key={`influencer-${inf.id}`}>{inf.fullname}</li>
              })}
            </ul>
            {longList && (
              <>
                {showFullInfluencerList[sheet.id] ? (
                  <button
                    onClick={() =>
                      setShowFullInfluencerList({ ...showFullInfluencerList, [sheet.id]: false })
                    }
                    className="btn btn-light"
                  >
                    Show Less
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      setShowFullInfluencerList({ ...showFullInfluencerList, [sheet.id]: true })
                    }
                    className="btn btn-light mb-2"
                  >
                    Show {sheet.influencer_ids.length - 6} More
                  </button>
                )}
              </>
            )}
          </td>
        )
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          CREATED
        </th>
      ),
      td: (sheet) => {
        return (
          <td className="text-center">{date.format(new Date(sheet.created_at), 'MMM D YYYY')}</td>
        )
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          CREATED BY
        </th>
      ),
      td: (sheet) => {
        return <td className="text-center">{sheet.creator_nickname}</td>
      },
    },
    {
      th: (
        <th className="text-center" scope="col">
          DELETE
        </th>
      ),
      td: (sheet) => {
        return (
          <td
            onClick={() => {
              setSheetToDelete(sheet.id)
              setShow(true)
            }}
            className="ps-4 selectable text-center"
            data-sheet-id={sheet.id}
          >
            <FaTrash />
          </td>
        )
      },
    },
  ]

  return (
    <div className="container">
      <Helmet>
        <title>Talent Pitch Pro - Talent Sheets</title>
      </Helmet>
      <>
        <div className="mt-2">
          <input
            style={{ width: '320px' }}
            className="form-control my-3"
            type="text"
            placeholder="filter by title..."
            onChange={(e) => {
              setSheetTitleFilterInput(e.target.value)
            }}
          />
        </div>

        <div className="form-check my-3">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            checked={onlyShowMine}
            onChange={() => {
              if (onlyShowMine) {
                setOnlyShowMine(false)
              } else {
                setOnlyShowMine(true)
              }
            }}
          />
          <label
            style={{ fontSize: '1.1em' }}
            className="form-check-label"
            htmlFor="flexCheckChecked"
          >
            Only display sheets created by me
          </label>
        </div>
      </>
      <button
        data-purpose="create-new-sheet"
        className="btn btn-primary my-2"
        onClick={() => {
          dispatch(resetSheet())
          const navTo = sheetType === 'talent' ? '/talent-sheet' : '/campaign'
          navigate(navTo)
        }}
      >
        Create New
      </button>

      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <table
        className={`table table-${teamSettings.displayTableColor || 'dark'} table-striped w-100`}
      >
        <thead>
          <tr className="">
            {tableColumns.map((col, i) => {
              return <Fragment key={`th-${i}`}>{col.th}</Fragment>
            })}
          </tr>
        </thead>
        <tbody>
          {_.map(displaySheets, (sheet) => {
            return (
              <tr key={`sheet-${sheet.id}`} data-type="sheet-table-row">
                {tableColumns.map((col, i) => {
                  return (
                    <Fragment key={`sheet-${sheet.id}-td-${i}`}>
                      {col.td(sheet, displaySettings)}
                    </Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        {_.isEmpty(displaySheets) && sheetsLoading && (
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        )}
      </div>
      {showPageMenu && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      <ConfirmModal show={show} handleClose={handleCloseDeleteModal} />
    </div>
  )
}
