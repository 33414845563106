import React, { useEffect, useState } from 'react'
import { changeSetting, fetchSettings, fetchUserStatus } from './redux/userSlice'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import ManageOrganization from './ManageOrganization'
import _ from 'lodash'
import axios from 'axios'
import { selectTeamSettings } from './redux/teamSlice'

export default function UserSettings() {
  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)
  const dispatch = useDispatch()
  const [saveNotification, setNotification] = useState(null)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMessage, setPasswordMessage] = useState(null)
  const [teams, setTeams] = useState([])
  const [teamMembers, setTeamMembers] = useState([])
  const [fakeId, setFakeId] = useState('')

  const [selectedMenuItem, setSelectedMenuItem] = useState('personal')

  const userIsOrgAdmin = teamSettings.is_team_admin

  const menuItems = [
    {
      id: 'personal',
      name: 'Personal Details',
    },
    {
      id: 'password',
      name: 'Change Password',
    },
    {
      id: 'organization',
      name: 'Manage Organization',
      hide: !userIsOrgAdmin,
    },
  ]

  const refreshTeamMembers = () => {
    axios.get('/api/user/team/members').then((res) => {
      setTeamMembers(res.data)
    })
  }

  useEffect(() => {
    if (userIsOrgAdmin) {
      refreshTeamMembers()
    }
  }, [teamSettings])

  useEffect(() => {
    dispatch(fetchSettings())

    if (user.is_admin) {
      axios.get('/api/admin/teams').then((res) => {
        setTeams(res.data)
      })
    }
  }, [user.is_admin, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      full_name: user.settings.full_name.trim(),
      nickname: user.settings.nickname.trim(),
    }
    console.log(data)
    axios.post('/api/user/configuration', data).then(() => {
      setNotification('Settings Saved!')
      window.setTimeout(() => {
        setNotification(null)
      }, 1500)
    })
  }

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault()
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    }

    if (newPassword !== confirmPassword) {
      setPasswordMessage('New password and confirmation do not match')
      window.setTimeout(() => {
        setPasswordMessage(null)
      }, 1800)
      return
    }

    if (newPassword.length < 6) {
      setPasswordMessage('Choose a longer password')
    }

    const response = await axios.post('/api/user/account/change-password', data)
    if (_.get(response, 'data.success')) {
      setPasswordMessage('Password changed.')
      window.setTimeout(() => {
        setPasswordMessage(null)
      }, 1200)
    }
  }

  if (!user.settings.email) {
    return 'Loading...'
  }

  const team_name = teamSettings.name

  return (
    <div className="container mt-2">
      <div className="row my-3">
        <h4 className="text-bold text-center">
          Your Organization: <span className="ms-3">{team_name}</span>
        </h4>
      </div>
      <div className="row">
        <div className="col-12 col-md-3">
          <div className="nav nav-pills mb-auto flex-column">
            {menuItems.map((item) => {
              if (item.hide) {
                return null
              }
              return (
                <span
                  key={`menu-${item.id}`}
                  onClick={() => {
                    setSelectedMenuItem(item.id)
                  }}
                  className={`selectable nav-link ${
                    selectedMenuItem === item.id ? 'active' : 'link-dark'
                  }`}
                >
                  {item.name}
                </span>
              )
            })}
          </div>
        </div>

        <div className="col-12 col-md-9">
          {selectedMenuItem === 'personal' && (
            <div className="row">
              <form onSubmit={handleSubmit} className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={user.settings.email}
                    readOnly
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="input2" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="input2"
                    value={user.settings.full_name}
                    onChange={(e) => {
                      dispatch(changeSetting({ name: 'full_name', value: e.target.value }))
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="input3" className="form-label">
                    Nickname (appears on the list of talent sheets)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="input3"
                    value={user.settings.nickname}
                    onChange={(e) => {
                      dispatch(changeSetting({ name: 'nickname', value: e.target.value }))
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input type="submit" className="btn btn-primary" value="Save Changes" />
                </div>
                <div className="message-box">{saveNotification && saveNotification}</div>
              </form>
            </div>
          )}
          {selectedMenuItem === 'password' && (
            <div className="row">
              {/* <h3 className="text-center">Change Password</h3> */}
              <form onSubmit={handleChangePasswordSubmit} className="w-50">
                <div className="mb-3">
                  <label htmlFor="oldPass" className="form-label">
                    Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPass"
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newPass" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPass"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="repeatNewPass" className="form-label">
                    Repeat New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="repeatNewPass"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3 mt-1">
                  <input type="submit" className="btn btn-primary" value="Change Password" />
                </div>
                <div className="message-box">{passwordMessage && passwordMessage}</div>
              </form>
            </div>
          )}
          {selectedMenuItem === 'organization' && (
            <ManageOrganization
              refreshTeamMembers={refreshTeamMembers}
              teamMembers={teamMembers}
            />
          )}
        </div>
      </div>
      {user.teams.length > 1 && (
        <div className="row mb-3">
          <hr />
          <h3>Select Organization</h3>
          <div className="col-12 col-md-6">
            <ul className="list-group teams-list">
              {user.teams.map((t) => {
                return (
                  <li
                    onClick={() => {
                      console.log(t)
                      axios
                        .post('/api/user/switch-team', {
                          team_id: t.id,
                        })
                        .then(() => {
                          window.location.reload()
                        })
                    }}
                    className={`list-group-item list-group-item-action selectable ${
                      user.selectedTeamId === t.id ? 'active' : ''
                    }`}
                    key={`team-${t.id}`}
                  >
                    {t.team_name}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
      {user.is_admin && (
        <div className="row mb-3">
          <hr />
          <h3>Admin: Select Organization</h3>
          <div className="col-12 col-md-6">
            <ul className="list-group teams-list">
              {teams.map((t) => {
                if (!t.is_active) return null
                return (
                  <li
                    onClick={() => {
                      console.log(t)
                      axios
                        .post('/api/admin/settings', {
                          team_id: t.id,
                        })
                        .then(() => {
                          window.location.reload()
                        })
                    }}
                    className={`list-group-item list-group-item-action selectable ${
                      team_name === t.team_name ? 'active' : ''
                    }`}
                    key={`team-${t.id}`}
                  >
                    {t.team_name}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
      {user.is_staff && (
        <div className="row mb-3">
          <h3>Admin: Spoof user</h3>
          <p>Your user id: {user.account_id}</p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              axios.post('/api/admin/user/settings/fake-id', { user_id: fakeId }).then(() => {
                dispatch(fetchUserStatus())
              })
            }}
          >
            <div className="mb-3">
              <label htmlFor="fake-id-input" className="form-label">
                id
              </label>
              <input
                id="fake-id-input"
                type="text"
                className="form-control w-25"
                value={fakeId}
                onChange={(e) => {
                  setFakeId(e.target.value)
                }}
              />
            </div>
            <div className="mb-3">
              <input type="submit" className="btn btn-primary" value="Spoof" />
            </div>
          </form>
        </div>
      )}
    </div>
  )
}
