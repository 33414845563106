import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { sendEvent } from 'analytics'

import SocialIcon from './components/SocialIcon'
import { joinClassNames, safelyMillify } from './utils'

export default function TalentSheetRosterItemCircular({
  specialText,
  profile,
  handleClick,
  displaySettings = {
    displayRosterNameColor: 'orange',
  },
  source = 'Talent Sheet',
}) {
  const totalFollowers = _.sum(profile.socials.map((s) => (s.followers ? s.followers : 0)))
  const picture_url = profile.custom_picture || profile.socials[0].picture_main_url
  const params = useParams()

  let customNameFont =
    displaySettings.displayTalentNameFont || displaySettings.displayRosterTitleFont || 'default'

  return (
    <div
      data-influencer-id={profile.id}
      className="col-md-6 col-lg-4 roster-influencer-holder circle-portraits mb-4"
    >
      <div
        style={{ height: '100%' }}
        className="d-flex flex-column justify-content-between p-3 roster-box bg-white rounded shadow"
      >
        {profile.tagline && (
          <div style={{ fontSize: '1em', minHeight: '1.5em' }} className="text-center text-bold">
            {profile.tagline}
          </div>
        )}
        <div className="d-flex flex-column justify-content-around pt-2">
          <div
            onClick={() => handleClick()}
            className="selectable portrait-rounded text-center mb-3"
          >
            <div className="image-holder">
              <img src={picture_url} />
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap justify-content-center align-items-center social-follower-counts text-center">
              {profile.socials.map((social, i) => {
                return (
                  <div
                    className="d-flex flex-column align-items-center mb-1 ms-1 roster-social-icon"
                    key={`profile-${social.id}`}
                  >
                    <div className="mb-1">
                      <a
                        className="text-black"
                        onClick={() => {
                          sendEvent('social_view', {
                            influencer_id: profile.id,
                            platform: social.platform,
                            social_id: social.id,
                            sheet_id: params.talentSheetId,
                            team_id: params.teamId,
                            from_roster: source === 'Public Roster',
                          })
                        }}
                        href={social.profile_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialIcon size="36" platform={social.platform} />
                      </a>
                    </div>
                    <div className="text-center rounded-pill follower-display">
                      {safelyMillify(social.followers)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            handleClick()
          }}
          className={joinClassNames([
            `selectable roster-name`,
            `color-${displaySettings.displayRosterNameColor}`,
            customNameFont ? `font-${customNameFont}` : null,
            `text-center influencer-name mb-2`,
          ])}
        >
          {profile.fullname}
        </div>
      </div>
    </div>
  )
}
