import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'
import { fetchResponseSheet } from './castingCallsSlice'

interface Influencer {
  note: string
}

interface UserSheetState {
  id: number | null
  title: string
  description: string | null
  updated_at: string
  created_at: string
  creator_user_id: string
  team_id: string
  sheet_type: string
  creator_nickname: string
  creator_full_name: string
  creator_email: string
  influencers: Record<string, Influencer>
  error: string | null
  categories: string[]
  loading: 'idle' | 'pending' | 'finished'
  saveStatus: 'clean' | 'dirty' | 'saved'
  usePublicTitle: boolean
  hasUnsavedChanges: boolean
  influencer_ids: string[]
  notes: Record<string, string>
}

interface FetchOneUserSheetPayload {
  sheet: UserSheetState
}

export const fetchOneUserSheet = createAsyncThunk(
  'talentsheet/fetchOne',
  async ({ hash_id, sheet_type }: { hash_id: string; sheet_type: string }) => {
    let route = `/api/talent-sheets/${hash_id}`
    if (sheet_type) {
      route = `/api/talent-sheets/${hash_id}?sheet_type=${sheet_type}`
    }
    const response = await axios.get(route)
    return response.data
  },
)

const defaultSheet: UserSheetState = {
  id: null,
  title: '',
  description: null,
  updated_at: '',
  created_at: '',
  creator_user_id: '',
  team_id: '',
  sheet_type: '',
  creator_nickname: '',
  creator_full_name: '',
  creator_email: '',
  influencers: {},
  error: null,
  categories: [],
  loading: 'idle',
  saveStatus: 'clean',
  usePublicTitle: false,
  hasUnsavedChanges: false,
  influencer_ids: [],
  notes: {},
}

export const userSheetSlice = createSlice({
  name: 'userSheet',
  initialState: {
    ...defaultSheet,
  },
  reducers: {
    setInfluencers: (state, { payload }: PayloadAction<string[]>) => {
      state.influencer_ids = payload
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    updateNotes: (state, { payload }: PayloadAction<{ notes: Record<string, string> }>) => {
      Object.keys(payload.notes).forEach((key) => {
        state.influencers[key].note = payload.notes[key]
      })
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    setTitle: (state, { payload }: PayloadAction<string>) => {
      state.title = payload
      state.saveStatus = 'dirty'
      state.hasUnsavedChanges = true
    },
    resetSheet: () => {
      return {
        ...defaultSheet,
      }
    },
    setNote: (state, { payload }: PayloadAction<{ id: string; note: string }>) => {
      state.notes[payload.id] = payload.note
      state.hasUnsavedChanges = true
    },
    setCategories: (state, { payload }: PayloadAction<string[]>) => {
      state.categories = payload
    },
    setCampaignDetails: (state, { payload }: PayloadAction<{ id: string; note: string }>) => {
      state.notes[payload.id] = payload.note
    },
    setSaveStatus: (state, { payload }: PayloadAction<'saved' | 'dirty'>) => {
      state.saveStatus = payload
      state.hasUnsavedChanges = false
    },
    setDescription: (state, { payload }: PayloadAction<string | null>) => {
      state.description = payload
      if (payload === null) {
        state.description = ''
        state.usePublicTitle = false
      } else {
        state.usePublicTitle = true
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneUserSheet.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(
        fetchOneUserSheet.fulfilled,
        (state, { payload }: PayloadAction<FetchOneUserSheetPayload>) => {
          const fetchedSheet = payload.sheet

          return {
            ...state,
            ...fetchedSheet,
            usePublicTitle: fetchedSheet.description !== null,
            loading: 'finished',
            saveStatus: 'clean',
            hasUnsavedChanges: false,
          }
        },
      )
      .addCase(fetchResponseSheet.fulfilled, (state, { payload }) => {
        console.log(payload)
        if (!payload.talent_sheet_id) {
          return defaultSheet
        }
        return
        const { responseSheet } = payload
        const { influencers } = responseSheet
        const influencer_ids = Object.keys(influencers)
        const notes = _.mapValues(influencers, (influencer) => influencer.note)
        return {
          ...state,
          ...responseSheet,
          influencer_ids,
          notes,
          usePublicTitle: responseSheet.description !== null,
          loading: 'finished',
          saveStatus: 'clean',
          hasUnsavedChanges: false,
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setInfluencers,
  setTitle,
  setNote,
  resetSheet,
  setCategories,
  setSaveStatus,
  setDescription,
  updateNotes,
} = userSheetSlice.actions

export default userSheetSlice.reducer
