import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import millify from 'millify'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const LineChart = ({ influencerData: socialData }) => {
  if (!socialData.stat_history) return null

  const data = {
    labels: socialData.stat_history.map((d) => d.month),
    datasets: [
      {
        label: 'Followers',
        data: socialData.stat_history.map((d) => d.followers),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        title: null,
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return millify(value)
          },
        },
      },
    },
  }

  return (
    <>
      <Line data={data} options={options} />
    </>
  )
}

export default LineChart
