import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { fetchPublicInfluencers, fetchPublicInfluencerProfile } from './influencerSlice'
import { fetchUserStatus, setUser } from './userSlice'
import { fetchSingleSheet } from './allSheetsSlice'

import _ from 'lodash'
import { Team, TeamSettings } from 'types'

interface TeamState {
  id: string
  name: string
  image_url: string | null
  authed: boolean
  loaded: boolean
  settings: Partial<TeamSettings>
  team_name?: string
  team_image_url?: string
  team_bio?: string
  team_contact_email?: string
  team_homepage?: string
  team_phone?: string
  allTeamSettings: {
    [key: string]: Partial<TeamSettings>
  }
}

const initialState: TeamState = {
  id: '',
  name: '',
  image_url: null,
  authed: false,
  loaded: false,
  settings: {},
  allTeamSettings: {},
}

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        if (!payload.sheet || _.isEmpty(payload.sheet)) return

        if (payload.sheet.team_name) {
          state.name = payload.sheet.team_name
        }
        if (payload.sheet.team_image_url) {
          state.image_url = payload.sheet.team_image_url
        }
        if (!payload.sheet.settings) return
        state.settings = { ...state.settings, ...payload.sheet.settings }
        const sheetTeamSettings = {
          ...payload.sheet.settings,
          name: payload.sheet.team_name,
          image_url: payload.sheet.team_image_url,
        }

        if (payload.sheet?.team_id) {
          state.allTeamSettings = {
            ...state.allTeamSettings,
            [payload.sheet.team_id]: sheetTeamSettings,
          }
        }
      })
      .addCase(fetchPublicInfluencers.fulfilled, (state, { payload }) => {
        state.name = payload.team
        if (_.isEmpty(payload.team_settings)) return

        state.image_url = payload.team_settings.team_image_url
        state.settings = {
          ...state.settings,
          ...payload.team_settings,
          ...payload.team_settings.settings,
        }

        state.team_bio = payload.team_settings.team_bio
        state.team_name = payload.team_settings.team_name
        state.team_image_url = payload.team_settings.team_image_url
        state.team_contact_email = payload.team_settings.team_contact_email
        state.team_homepage = payload.team_settings.team_homepage
        state.team_phone = payload.team_settings.team_phone

        if (!_.isEmpty(payload.teamDetails)) {
          const previousDetails = state.allTeamSettings[payload.teamDetails.id] || {}
          state.allTeamSettings = {
            ...state.allTeamSettings,
            [payload.teamDetails.id]: { ...previousDetails, ...payload.teamDetails },
          }
        }
      })
      .addCase(fetchUserStatus.fulfilled, (state, { payload }) => {
        if (_.isEmpty(payload.team?.settings)) return
        state.settings = {
          ...state.settings,
          ...payload.team.settings,
        }
        state.allTeamSettings[payload.selectedTeamId] = {
          ...payload.team.settings,
          name: payload.team.name,
          image_url: payload.team.image_url,
          is_team_admin: payload.team.is_team_admin,
          is_team_contact: payload.team.is_team_contact,
          role: payload.team.role,
        }
      })
      .addCase(fetchSingleSheet.fulfilled, (state, { payload }) => {
        if (!payload.sheet) return

        if (!payload.sheet?.settings) return
        const sheetTeamSettings = {
          ...payload.sheet.settings,
          name: payload.sheet.team_name,
          image_url: payload.sheet.team_image_url,
        }

        if (payload.sheet?.team_id) {
          state.allTeamSettings = {
            ...state.allTeamSettings,
            [payload.sheet.team_id]: sheetTeamSettings,
          }
        }
      })
      .addCase(setUser, (state, { payload }) => {
        if (payload.team_name !== undefined) {
          state.name = payload.team_name
        }

        if (payload.team_image_url !== undefined) {
          state.image_url = payload.team_image_url
        }

        if (_.get(payload, 'teams[0].id', undefined) !== undefined) {
          state.id = _.get(payload, 'teams[0].id', '')
        }
        if (_.isEmpty(payload.teamSettings)) return
        state.settings = {
          ...state.settings,
          ...payload.teamSettings,
        }
      })
  },
})

export const selectTeamSettings = (state: any) => {
  const selectedTeamId = state.user.selectedTeamId
  const teamSettings = state.team.allTeamSettings[selectedTeamId]
  return teamSettings || {}
}

export default teamSlice.reducer
