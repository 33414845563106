import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { Fragment, useEffect, useState, useRef } from 'react'
import _, { map } from 'lodash'
import { fetchFavoriteInfluencers, toggleFavoriteInfluencer } from './redux/userSlice'
import { useDispatch, useSelector } from 'react-redux'

import { BsXCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import PageMenu from './PageMenu'
import { Rings } from 'react-loader-spinner'
import SocialIcon from './components/SocialIcon'
import axios from 'axios'
import config from './config'
import { fetchFilteredSearchInfluencers } from './redux/searchSlice'
import { safelyMillify } from './utils'
import { useDetectClickOutside } from 'react-detect-click-outside'
import useOutsideClick from 'hooks/useOutsideClick'
import { selectTeamSettings } from './redux/teamSlice'

const SortArrow = ({ direction }) => {
  const arrow = direction === 'asc' ? '▲' : '▼'
  return <span className="sort-arrow">{arrow}</span>
}

const ClickablePopupTagChooser = ({ label, tagWidth, choices, handleChoice }) => {
  const [openPopup, setOpenPopup] = useState(false)

  const togglePopup = () => {
    setOpenPopup(!openPopup)
  }

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (openPopup) {
        setOpenPopup(false)
      }
    },
  })

  return (
    <div className="col mb-3">
      <div
        style={{ width: tagWidth }}
        className={`badge selectable hover-darken rounded-pill bg-light-contrast text-dark py-2 px-3`}
        onClick={() => {
          togglePopup()
        }}
      >
        {label}
      </div>
      {openPopup && (
        <div className="position-relative">
          <div
            style={{ marginLeft: '-30px' }}
            ref={ref}
            className="position-absolute top-0 bg-white p-3 rounded shadow-sm popup popup-menu tags-popup"
          >
            {choices.map((choice, i) => {
              return (
                <div
                  key={`choice-${i}`}
                  className="badge selectable hover-darken rounded-pill bg-light-contrast text-dark py-2 px-3 mx-1 my-1"
                  onClick={() => {
                    handleChoice(choice)
                    setOpenPopup(false)
                  }}
                >
                  {choice.displayName}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

const getSubcategoryName = (subcategory_id, subcategories) => {
  return subcategories[subcategory_id]
}

const pageBaseUrl = '/social-accounts'

/**
 * @param {object} props
 * @param {string[]} props.firstChoices
 * @param {string[]} props.values
 *
 * @returns {JSX.Element}
 * @description Renders a filter with a select dropdown
 *
 */

const MultiLevelFilter = ({
  label,
  firstChoices,
  values,
  handleChange,
  firstChoice,
  secondChoice,
}) => {
  const [openPopup, setOpenPopup] = useState(false)
  const popupRef = useRef()

  const togglePopup = () => {
    setOpenPopup(!openPopup)
  }

  useOutsideClick(popupRef, () => {
    if (openPopup) {
      setOpenPopup(false)
    }
  })

  const filterLabel =
    firstChoice && secondChoice ? `${label} ${firstChoice} - ${secondChoice}%` : label

  return (
    <div className="pe-3 col-3">
      <div
        className="badge selectable hover-darken rounded-pill bg-light-contrast text-dark py-2 px-3"
        onClick={togglePopup}
      >
        {label}
      </div>

      {openPopup && (
        <div className="position-relative" ref={popupRef}>
          <div className="position-absolute top-0 bg-white p-3 rounded shadow-sm popup popup-menu">
            <select
              value={firstChoice || 'choose'}
              onChange={(e) => {
                if (e.target.value === 'choose') {
                  handleChange(null, null)
                } else {
                  handleChange(e.target.value, null)
                }
                // togglePopup()
              }}
              className="form-select form-select-sm mb-3 mt-2"
              aria-label=".form-select-lg example"
            >
              <option value={'choose'}>-</option>
              {firstChoices.map((filterCategory, i) => {
                return (
                  <option key={`choice-${i}`} value={filterCategory.value}>
                    {filterCategory.label}
                  </option>
                )
              })}
            </select>

            {firstChoice && (
              <div>
                <select
                  value={secondChoice || 'choose'}
                  onChange={(e) => {
                    if (e.target.value === 'choose') {
                      handleChange(null, null)
                    } else {
                      handleChange(firstChoice, e.target.value)
                    }
                    togglePopup()
                  }}
                  className="form-select form-select-sm mb-3 mt-2"
                  aria-label=".form-select-lg example"
                >
                  <option value={'choose'}>-</option>
                  {values.map((val, i) => {
                    return (
                      <option key={`value-${i}`} value={val.value}>
                        {val.display}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const RenderFilter = ({
  inputProps,
  label,
  value,
  handleChange,
  optionsArray,
  displayValue,
  range,
}) => {
  const [openPopup, setOpenPopup] = useState(false)
  const popupRef = useRef() // Reference to the popup div

  const togglePopup = () => {
    setOpenPopup(!openPopup)
  }

  useOutsideClick(popupRef, () => {
    if (openPopup) {
      setOpenPopup(false)
    }
  })

  return (
    <div className="pe-3 col-3 mb-3">
      <div
        className="badge selectable hover-darken rounded-pill bg-light-contrast text-dark py-2 px-3"
        onClick={togglePopup}
      >
        {label}: {value ? displayValue(value) : 'All'}
      </div>

      {openPopup && (
        <div className="position-relative" ref={popupRef}>
          <div className="position-absolute top-0 bg-white p-3 rounded shadow-sm popup popup-menu">
            <span className="">{label} </span>
            <select
              value={value || 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  handleChange(null)
                } else {
                  handleChange(e.target.value)
                }
                togglePopup()
              }}
              className="form-select form-select-sm mb-3 mt-2"
              aria-label=".form-select-lg example"
            >
              <option value={'all'}>All</option>
              {optionsArray}
            </select>
            {range && <option></option>}
          </div>
        </div>
      )}
    </div>
  )
}

export default function AccountSearch({
  handleAddToTalentSheet,
  handleAddMultiple,
  excludedIds,
  filteredIds,
  pageLimit = 80,
  team = 'friends',
  showFavorites = false,
  onlyShowIds = null,
  profileType = 'search',
}) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)

  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1
  const followerThresholds = [[0, 5000, 20000, 1e5, 5 * 1e5, 1e6]]

  // const [accountsToDisplay, setAccountsToDisplay] = useState([])

  const favorites = useSelector((state) => state.user.favoriteInfluencers)

  const searchState = useSelector((state) => state.search)
  const reduxFilters = useSelector((state) => state.searchFilters)

  const categories = useSelector((state) => state.categories.categories)
  const subcategoryList = useSelector((state) => state.categories.subcategories)

  const searchFilters = reduxFilters
  const filterDispatch = dispatch

  const influencers = useSelector((state) => state.search.influencers.entities)

  const setSubcategoryFilters = (payload) => {
    dispatch({ type: 'SET_SUBCATEGORY', payload })
  }

  const subcategoryFilters = useSelector((state) => state.searchFilters.subcategories)

  const [pendingCategoryFilter, setPendingCategoryFilter] = useState({
    category_id: null,
    subcategory_id: null,
  })

  useEffect(() => {
    if (teamSettings.hasFriendsSearch) {
      dispatch(fetchFavoriteInfluencers())
    }
  }, [teamSettings])

  useEffect(() => {
    const subcategories = _.map(subcategoryFilters, (sc) => sc.subcategory_id)
    const params = {
      page,
      limit: pageLimit,
      ...searchFilters,
      subcategories,
    }

    if (searchFilters.followers) {
      const a = searchFilters.followers.split('-')
      params.followers_from = Number(a[0])
      if (!_.isEmpty(a.slice(-1)[0])) {
        params.followers_to = Number(a.slice(-1)[0])
      }
    }

    dispatch(fetchFilteredSearchInfluencers({ ...params, team, only_ids: onlyShowIds }))
  }, [
    searchFilters.us_audience_share,
    searchFilters.female,
    searchFilters.male,
    searchFilters.followers,
    searchFilters.engagement_rate,
    page,
    searchFilters.platform,
    // searchFilters.age_group,
    searchFilters.age_target_share,
    searchFilters.target_gender_share,
    searchFilters.avg_views,
    searchFilters.account_gender,
    // searchFilters.orderBy,
    subcategoryFilters,
    onlyShowIds,
  ])

  const totalPages = Math.ceil(searchState[searchFilters.platform].total_accounts / pageLimit)

  const handleOrderBy = (value) => {
    filterDispatch({ type: 'SET_ORDER_BY', payload: value })
  }

  const showPageMenus = false

  let tableColumns = [
    {
      th: (
        <th scope="col" className="text-center">
          Profile
        </th>
      ),
      td: (account) => {
        return (
          <td className="portrait-rounded align-middle text-center">
            <div className="margin">
              <Link to={`/${profileType}/profile/${account.id}`}>
                <img data-purpose="influencer-portrait" src={account.picture_main_url} />
              </Link>
            </div>
          </td>
        )
      },
    },
    {
      th: <th scope="col"></th>,
      td: (account) => {
        return (
          <td className="align-middle">
            <a
              className="no-decoration"
              href={account.profile_url}
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon fontSize="2em" platform={account.platform} />{' '}
              <span className="ps-1">
                {account.platform === 'youtube' ? '' : account.platform_account_name}
              </span>
            </a>
          </td>
        )
      },
    },
    {
      th: (
        <th scope="col" className="text-center">
          Talent Name
        </th>
      ),
      td: (account) => {
        const influencer = influencers[account.id]
        const displayName = _.get(influencer, 'fullname')
        return <td className="align-middle text-center">{displayName}</td>
      },
    },
    {
      th: (
        <th scope="col" className="text-center">
          Handle
        </th>
      ),
      td: (account) => {
        const displayName = account.platform_account_nickname
        return <td className="align-middle text-center">{displayName}</td>
      },
    },
    {
      th: (
        <th
          scope="col"
          className="text-center selectable"
          onClick={() => {
            handleOrderBy('us_audience_share')
          }}
        >
          USA Audience{' '}
          {searchFilters.orderBy === 'us_audience_share' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        const us_audience_share = Number(100 * account.us_audience_share)
          .toString()
          .slice(0, 2)
        return (
          <td className="align-middle text-center">
            {us_audience_share === '0' ? '-' : `${us_audience_share}%`}
          </td>
        )
      },
    },
    {
      th: (
        <th
          scope="col"
          className="text-center selectable"
          onClick={() => {
            handleOrderBy('audience_female_percent')
          }}
        >
          Female/Male{' '}
          {searchFilters.orderBy === 'audience_female_percent' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        const female_percent = Math.round(Number(account.audience_female_percent))
        const male_percent = Math.round(Number(account.audience_male_percent))
        return (
          <td className="align-middle text-center">
            {female_percent + male_percent === 0 ? '-' : `${female_percent}/${male_percent}`}
          </td>
        )
      },
    },
    {
      th: (
        <th
          className="text-center selectable"
          scope="col"
          onClick={() => filterDispatch({ type: 'SET_ORDER_BY', payload: 'followers' })}
        >
          Followers{' '}
          {searchFilters.orderBy === 'followers' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        let displayNumber = account.followers
        return (
          <td className="align-middle text-center">
            {!displayNumber || displayNumber == 0 ? '' : safelyMillify(displayNumber)}{' '}
          </td>
        )
      },
    },
    {
      th: (
        <th
          className="text-center selectable"
          scope="col"
          onClick={() => filterDispatch({ type: 'SET_ORDER_BY', payload: 'engagement_rate' })}
        >
          Engagement Rate{' '}
          {searchFilters.orderBy === 'engagement_rate' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        let displayNumber = `${Number(account.engagement_rate).toFixed(2)}`.slice(0, 4) + '%'
        if (displayNumber.slice(-1)[0] === '.') {
          displayNumber = displayNumber.slice(0, -1)
        }
        return (
          <td className="align-middle text-center">
            {displayNumber == '0.00%' ? '-' : displayNumber}
          </td>
        )
      },
    },
    {
      th: (
        <th
          className="text-center selectable"
          scope="col"
          onClick={() =>
            filterDispatch({ type: 'SET_ORDER_BY', payload: 'targeted_age_group_share' })
          }
        >
          {searchFilters.age_group ? `Audience ${searchFilters.age_group}` : 'Audience Age'}
          {searchFilters.orderBy === 'targeted_age_group_share' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        if (!account.targeted_age_group_share)
          return <td className="align-middle text-center">-</td>
        let displayNumber = `${Number(account.targeted_age_group_share)}%`
        if (displayNumber.slice(-1)[0] === '.') {
          displayNumber = displayNumber.slice(0, -1)
        }

        return (
          <td className="align-middle text-center">{displayNumber == 0 ? '' : displayNumber}</td>
        )
      },
    },
    {
      th: (
        <th
          className="text-center selectable"
          scope="col"
          onClick={() => filterDispatch({ type: 'SET_ORDER_BY', payload: 'avg_views' })}
        >
          {searchFilters.platform === 'instagram' ? `Average Reels Views` : `Average Views`}
          {searchFilters.orderBy === 'avg_views' && (
            <SortArrow direction={searchFilters.orderDirection} />
          )}
        </th>
      ),
      td: (account) => {
        let displayNumber =
          account.platform === 'instagram' ? account.avg_reels_plays : account.avg_views
        if (!displayNumber) return <td className="align-middle text-center">-</td>

        // if (displayNumber) {
        //   displayNumber = safelyMillify(displayNumber)
        // }

        return <td className="align-middle text-center">{safelyMillify(displayNumber)}</td>
      },
    },
  ]

  if (handleAddToTalentSheet) {
    tableColumns.push({
      th: <th className="text-center">Add</th>,
      td: (account) => (
        <td className="align-middle text-center">
          <button className="btn btn-primary" onClick={() => handleAddToTalentSheet(account.id)}>
            Add
          </button>
        </td>
      ),
    })
  }

  const handleToggleFavorite = (id) => {
    console.log('handleToggleFavorite', id)
    dispatch(toggleFavoriteInfluencer(id))
    const url = `/api/search/influencers/favorites`
    const data = {
      influencer_ids: [id],
    }
    const isPresent = favorites.includes(id)
    if (isPresent) {
      data.state = 'absent'
      console.log('already have id')
    } else {
      data.state = 'present'
      console.log('adding id')
    }
    axios.post(url, data).then((res) => {
      console.log(res.data)
      dispatch(fetchFavoriteInfluencers())
    })
  }

  if (showFavorites) {
    tableColumns = [
      {
        th: (
          <th scope="col" className="text-center">
            Favorite
          </th>
        ),
        td: (account) => {
          return (
            <td className="text-center align-middle">
              <div
                className="selectable"
                onClick={() => {
                  handleToggleFavorite(account.id)
                }}
              >
                {favorites.includes(account.id) ? (
                  <AiFillStar size={24} />
                ) : (
                  <AiOutlineStar size={24} />
                )}
              </div>
            </td>
          )
        },
      },
      ...tableColumns,
    ]
  }

  if (searchFilters.subcategories.length > 0) {
    tableColumns.push({
      th: (
        <th className="text-center selectable" scope="col">
          Tags
        </th>
      ),
      td: (account) => {
        const filteredTags = searchFilters.subcategories.map((sc) => sc.subcategory_id)
        const tags = account.subcategory_ids.map((id) => Number(id))

        const matchingTags = _.intersection(tags, filteredTags)

        return (
          <td className="align-middle text-center">
            {matchingTags
              .map((subcategory_id) => {
                const sc_name = getSubcategoryName(subcategory_id, subcategoryList)
                if (!config.categories.Gender.includes(sc_name)) return sc_name
              })
              .filter((s) => s)
              .map((sc_name) => {
                return (
                  <div
                    className="badge rounded-pill bg-light text-dark p-2 mb-2"
                    key={`sc-${sc_name}`}
                  >
                    {sc_name}
                  </div>
                )
              })}
          </td>
        )
      },
    })
  }

  const hasRepeatedAccounts = useSelector((state) => state.search.hasRepeatedAccounts)

  const shouldExcludeInfluencer = (influencer_id) => {
    if (excludedIds && excludedIds.includes(influencer_id)) return false

    if (filteredIds && !filteredIds.includes(influencer_id)) return false

    return true
  }

  const validCategories = useSelector((state) =>
    state.categories.categories.filter((cat) => state.categories.team_categories.includes(cat.id)),
  )

  let processedSearchResults = useSelector((state) => {
    const searchFilters = state.searchFilters

    const orderedResults = _.orderBy(
      state.search.processedResults,
      [searchFilters.orderBy],
      searchFilters.orderDirection,
    )

    return orderedResults
  })

  return (
    <div className="container mt-2">
      <div className="row mb-3 py-3">
        {['instagram', 'tiktok', 'youtube'].map((platform) => {
          return (
            <div key={platform} className="col text-center">
              <SocialIcon
                fontSize="2em"
                platform={platform}
                className="selectable"
                onClick={() => {
                  filterDispatch({
                    type: 'SET_PLATFORM',
                    payload: platform,
                  })
                }}
              />
            </div>
          )
        })}
      </div>
      <>
        <div className="row mb-3">
          <RenderFilter
            label="US Audience"
            value={searchFilters.us_audience_share}
            displayValue={(value) => `>${value}%`}
            handleChange={(value) => {
              filterDispatch({ type: 'SET_SHARE', payload: { name: 'us_audience_share', value } })
            }}
            optionsArray={Array(9)
              .fill(0)
              .map((_, i) => (
                <option key={i} value={10 * (i + 1)}>
                  {'>'}
                  {10 * (i + 1)}%
                </option>
              ))}
          />
          <RenderFilter
            label={searchFilters.platform === 'instagram' ? 'Average Reel Views' : 'Average Views'}
            value={searchFilters.avg_views}
            displayValue={(value) => {
              const arr = value.split('-')
              let result = safelyMillify(arr[0])
              if (arr[1]) {
                result += ` - ${safelyMillify(arr[1])}`
              } else {
                result += '+'
              }
              return result
            }}
            handleChange={(value) => {
              filterDispatch({ type: 'SET_SHARE', payload: { name: 'avg_views', value } })
            }}
            optionsArray={[0, 1e4, 3 * 1e4, 1e5, 3 * 1e5].map((v, i, arr) => (
              <option key={i} value={`${v}-${i === arr.length - 1 ? '' : arr[i + 1]}`}>
                {safelyMillify(v)}
                {i === arr.length - 1 ? '+' : `-${safelyMillify(arr[i + 1])}`}
              </option>
            ))}
          />
          <RenderFilter
            label="Followers"
            value={searchFilters.followers}
            displayValue={(value) => {
              const arr = value.split('-')
              let result = safelyMillify(arr[0])
              if (arr[1]) {
                result += ` - ${safelyMillify(arr[1])}`
              } else {
                result += '+'
              }
              return result
            }}
            handleChange={(value) => {
              filterDispatch({ type: 'SET_SHARE', payload: { name: 'followers', value } })
            }}
            optionsArray={[0, 1e4, 3 * 1e4, 1e5, 3 * 1e5, 1e6].map((v, i, arr) => (
              <option key={i} value={`${v}-${i === arr.length - 1 ? '' : arr[i + 1]}`}>
                {safelyMillify(v)}
                {i === arr.length - 1 ? '+' : `-${safelyMillify(arr[i + 1])}`}
              </option>
            ))}
          />
          <RenderFilter
            label="Engagement Rate"
            value={searchFilters.engagement_rate}
            displayValue={(value) => `>${value}%`}
            handleChange={(value) => {
              filterDispatch({ type: 'SET_SHARE', payload: { name: 'engagement_rate', value } })
            }}
            optionsArray={[1, 2, 5, 10, 20].map((v, i) => (
              <option key={i} value={v}>
                {'>'}
                {v}%
              </option>
            ))}
          />

          <RenderFilter
            label="Account Gender"
            value={searchFilters.account_gender}
            displayValue={(value) => value}
            handleChange={(value) => {
              filterDispatch({ type: 'SET_ACCOUNT_GENDER', payload: value })
            }}
            optionsArray={config.categories.Gender.map((v, i) => (
              <option key={i} value={v}>
                {v}
              </option>
            ))}
          />

          <MultiLevelFilter
            label={
              !(searchFilters.target_gender && searchFilters.target_gender_share)
                ? 'Audience Gender'
                : `${_.capitalize(searchFilters.target_gender)}  >${
                    searchFilters.target_gender_share
                  }%`
            }
            firstChoice={searchFilters.target_gender}
            secondChoice={searchFilters.target_gender_share}
            firstChoices={[
              { label: 'Male', value: 'male' },
              { label: 'Female', value: 'female' },
            ]}
            values={Array(9)
              .fill(0)
              .map((_, i) => 10 * (i + 1))
              .map((v) => {
                return {
                  display: `>${v}%`,
                  value: v,
                }
              })}
            handleChange={(genderGroup, targetShare) => {
              filterDispatch({
                type: 'SET_GENDER',
                payload: { target_gender: genderGroup, target_gender_share: targetShare },
              })
            }}
            filterDispatch={filterDispatch}
          />

          <MultiLevelFilter
            label={
              !searchFilters.age_group
                ? 'Audience Age'
                : `Ages ${searchFilters.age_group} - ${searchFilters.age_target_share}%`
            }
            firstChoice={searchFilters.age_group}
            secondChoice={searchFilters.age_target_share}
            firstChoices={config.age_groups.map((v) => ({ label: v, value: v }))}
            values={Array(9)
              .fill(0)
              .map((_, i) => 10 * (i + 1))
              .map((v) => {
                return {
                  display: `>${v}%`,
                  value: v,
                }
              })}
            handleChange={(ageGroup, targetShare) => {
              if (!ageGroup && !targetShare && searchFilters.orderBy === 'age_group') {
                filterDispatch({ type: 'SET_ORDER_BY', payload: 'followers' })
              }
              filterDispatch({
                type: 'SET_AGE_GROUP',
                payload: { age_group: ageGroup, age_target_share: targetShare },
              })
            }}
            filterDispatch={filterDispatch}
          />
        </div>
        <hr />
        <div className="row mb-3">
          {validCategories.map((category) => {
            return (
              <ClickablePopupTagChooser
                key={`cat-${category.id}`}
                label={`Tag: ${category.name}`}
                choices={category.subcategories.map((subcategory) => {
                  return {
                    ...subcategory,
                    displayName: subcategory.name,
                  }
                })}
                handleChoice={(choice) => {
                  console.log('chose ', choice)
                  let newSubcategoryFilters = _.uniqBy(
                    [
                      ...subcategoryFilters,
                      {
                        category_id: choice.category_id,
                        subcategory_id: choice.id,
                      },
                    ],
                    'subcategory_id',
                  )

                  setSubcategoryFilters(newSubcategoryFilters)
                }}
              />
            )
          })}
          <div className="d-flex flex-row">
            {subcategoryFilters.map((v) => {
              const category_name = categories.find((c) => c.id == v.category_id).name
              const subcategory_name = getSubcategoryName(v.subcategory_id, subcategoryList)
              return (
                <>
                  <div
                    key={`subcat-${v.subcategory_id}`}
                    className={`badge selectable rounded-pill bg-light-contrast text-dark py-2 px-3 me-2`}
                  >
                    {`${category_name} > ${subcategory_name}`}
                    <span
                      className="ps-2 align-center"
                      onClick={() => {
                        const newPendingCategories = subcategoryFilters.filter(
                          (pcf) => pcf.subcategory_id !== v.subcategory_id,
                        )
                        setSubcategoryFilters(newPendingCategories)
                      }}
                    >
                      <BsXCircleFill />
                    </span>
                  </div>
                </>
              )
            })}
          </div>
        </div>
        <p className="mt-4">
          <button
            onClick={() => {
              filterDispatch({ type: 'CLEAR_FILTERS' })
              setSubcategoryFilters([])
            }}
            className="btn btn-secondary btn-sm"
          >
            Clear Filters {team !== 'friends' && ` and Tags`}
          </button>
        </p>
        {hasRepeatedAccounts && (
          <p>
            <span className="text-bold">NOTE: </span>
            Some accounts may repeat where the same social media account belongs to more than one
            person. Check the "Talent Name" column.
          </p>
        )}
      </>

      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}

      {searchState.status === 'loading' ? (
        <div className="d-flex flex-column">
          <p className="text-bold">Loading...</p>
          <Rings
            height="80"
            width="80"
            color="#5863f8ff"
            radius="6"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col-12">
              {processedSearchResults && processedSearchResults.length > 0 && (
                <button
                  className="btn btn-info text-white btn-lg"
                  onClick={() => {
                    const visibleInfluencerIds = _.map(processedSearchResults, 'id')
                    handleAddMultiple(visibleInfluencerIds)
                  }}
                >
                  Add All to Sheet
                </button>
              )}
            </div>
          </div>

          <table
            className={`table ${
              _.get(user, 'teamSettings.colorTheme') || ''
            } table-striped mt-1 w-100 social-accounts-table`}
          >
            <thead>
              <tr>
                {tableColumns.map((col, i) => {
                  return <Fragment key={`th-${i}`}>{col.th}</Fragment>
                })}
              </tr>
            </thead>
            <tbody>
              {map(processedSearchResults, (account, i) => {
                if (!shouldExcludeInfluencer(account.id)) return null
                return (
                  <tr
                    data-influencer-id={account.id}
                    key={`influencer-${account.social_account_id}-${account.id}`}
                  >
                    {tableColumns.map((col, i) => {
                      return <Fragment key={`td-${i}`}>{col.td(account)}</Fragment>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
      {searchState.status === 'idle' && processedSearchResults.length === 0 && (
        <div className="d-flex flex-column">
          <p className="text-bold text-center py-2">No results found matching all filters</p>
        </div>
      )}

      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl={pageBaseUrl} />
      )}
    </div>
  )
}
