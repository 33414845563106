import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCastingCalls } from 'redux/castingCallsSlice'
import CastingCallDisplay from 'components/CastingCallDisplay'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate } from 'utils'

function shortenText(text, maxChars = 140) {
  if (text.length <= maxChars) {
    return text
  }
  return text.slice(0, maxChars) + '...'
}

const RenderCalls = ({ calls }) => {
  return calls.map((castingCall, i) => (
    <div key={`casting-call-${castingCall.id}`} className="row">
      <div
        key={`cc-${castingCall.id}`}
        className="col-12 col-md-6 p-2 mx-auto my-4 shadow-sm bg-white"
      >
        <CastingCallDisplay
          responseUrl={`/creator-call-response/${castingCall.id}`}
          {...castingCall}
        />
      </div>
    </div>
  ))
}

const CreatorCalls = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showResponded, setShowResponded] = useState(false)

  const castingCalls = useSelector((state) => state.castingCalls)

  useEffect(() => {
    dispatch(fetchCastingCalls())
  }, [])

  const unRespondedCalls = castingCalls.filter((castingCall) => {
    return !castingCall.response_sheet_id
  })

  const respondedCalls = castingCalls.filter((castingCall) => {
    return castingCall.response_sheet_id
  })

  return (
    <div className="container-fluid bg-sandy">
      <div className="container bg-gray-100 py-3">
        <h1 className="text-center py-3">Active Creator Calls</h1>
        {unRespondedCalls.length > 0 && <RenderCalls calls={unRespondedCalls} />}
        {/* {unRespondedCalls.map((castingCall, i) => (
          <div key={`casting-call-${castingCall.id}`} className="row">
            <div
              key={`cc-${castingCall.id}`}
              className="col-12 col-md-6 p-3 mx-auto my-4 shadow-sm bg-white"
            >
              <CastingCallDisplay
                responseUrl={`/creator-call-response/${castingCall.id}`}
                {...castingCall}
              />
            </div>
          </div>
        ))} */}
        <hr />
        <div className="row mb-3">
          <div className="col-12 col-md-6 mx-auto">
            <div className="form-check">
              <input
                id="showResponded"
                className="form-check-input"
                type="checkbox"
                value={showResponded}
                onChange={(e) => setShowResponded(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="showResponded">
                Show Calls My Team Has Responded To
              </label>
            </div>
          </div>
        </div>
        {showResponded && <RenderCalls calls={respondedCalls} />}
      </div>
    </div>
  )
}

export default CreatorCalls
