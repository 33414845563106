import _ from 'lodash'
import axios from 'axios'
import { useState, useCallback } from 'react'
import config from './config'
import CommonFormWrapper from 'components/CommonFormWrapper'
import { useDropzone } from 'react-dropzone'

function ContactForm() {
  const blankForm = {
    note: '',
  }
  const [uploadedKey, setUploadedKey] = useState(null)
  const [uploadedKeys, setUploadedKeys] = useState([])
  const [data, setData] = useState(blankForm)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)
  const [savedMessage, setSavedMessage] = useState(null)

  const uploadPhoto = async (files) => {
    const newKeys = []
    for (const file of files) {
      try {
        const { data } = await axios.get('/api/media-upload', {
          params: {
            filename: file.name,
            type: file.type,
          },
        })
        await axios.put(data.url, file, {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
        })
        newKeys.push(data.key)
      } catch (e) {
        console.log(e)
        setError('Something went wrong with the upload.')
      }
    }
    setUploadedKeys([...uploadedKeys, ...newKeys])
  }

  const renderUploadedImages = () => {
    return (
      <div className="row">
        {uploadedKeys.map((key, index) => (
          <div key={key} className="col-md-4 mb-4">
            <div className="card">
              <div className="d-flex justify-content-center">
                <img
                  className="card-img-top"
                  src={`https://tpp-uploads.us-east-1.linodeobjects.com/${key}`}
                  alt="uploaded"
                  style={{ maxWidth: '150px' }}
                />
              </div>

              <div className="card-body">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    const newKeys = [...uploadedKeys]
                    newKeys.splice(index, 1)
                    setUploadedKeys(newKeys)
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadPhoto(acceptedFiles)
    },
    [uploadedKeys],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (data.note.trim() === '') {
      setError('You must include a message')
      return
    }
    try {
      const formData = {
        ...data,
      }
      if (uploadedKeys.length > 0) {
        formData.uploadedKeys = uploadedKeys
      }

      axios.post('/api/user/contact-tpp', formData).then((r) => {
        setFormSent(true)
        setSavedMessage(r.data.message)
        setData(blankForm)
      })

      return
    } catch (e) {
      console.log(e)
      setError(
        'Something went wrong while trying to send your message, sorry. Please try again later',
      )
    }
  }

  return (
    <CommonFormWrapper>
      <>
        {formSent ? (
          <>
            <h2 className="text-center">Message Sent</h2>
            <p className="my-3 text-center">
              We have received your message and will be in touch shortly. Thank you!
            </p>
          </>
        ) : (
          <>
            <h1 className="mb-3 text-center">Contact Us</h1>
            <div className="row justify-content-center px-3">
              <div className="col-12">
                <form className="influencer-request-form" onSubmit={handleSubmit}>
                  <p>Problem with the site or otherwise need help? Let us know.</p>
                  <div>
                    <textarea
                      rows={8}
                      className="form-control"
                      placeholder="Your message"
                      value={data.note}
                      onChange={(e) => setData({ ...data, note: e.target.value })}
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-center mt-3">
                    {renderUploadedImages()}
                    <div {...getRootProps({ className: 'dropzone selectable' })}>
                      <input {...getInputProps()} multiple /> {/* Enable multiple */}
                      <p>Drag and drop or click to select files for upload</p>
                    </div>
                  </div>
                  <div className="message-box mt-2">
                    {error && <div className="error">{error}</div>}
                  </div>

                  <div className="d-flex justify-content-center">
                    <input className="btn btn-primary my-3" type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </>
    </CommonFormWrapper>
  )
}

export default ContactForm
