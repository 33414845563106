import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import axios from 'axios'
import { fetchSingleInfluencer } from './redux/influencerSlice'
import { useState, useEffect } from 'react'
import { Talent } from 'helpers/Talent'
import { toast, Slide } from 'react-toastify'
import RenderMarkdown from './components/RenderMarkdown'
import { Alert } from 'react-bootstrap'

function CustomDescription() {
  const showSavedToast = ({ customText = 'Custom Description Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const useRosterFullPhoto = useSelector((state) => {
    return _.get(state, ['user', 'teamSettings', 'displayUseRosterFullPhoto'], false)
  })

  const [newTagline, setTagline] = useState(null)

  const [newDescription, setDescription] = useState(null)
  const [notification, setNotification] = useState(null)
  const [error, setError] = useState(null)
  const [taglineError, setTaglineError] = useState(null)
  const dispatch = useDispatch()
  const params = useParams()
  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const previousDescription = useSelector((state) =>
    _.get(state, ['influencers', 'entities', params.influencerId, 'custom_description'], ''),
  )

  const profile = _.get(influencers, params.influencerId)

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
  }, [dispatch])

  if (!profile) {
    return 'Loading...'
  }

  const talent = new Talent(profile, socials)
  const saveNewDescription = async (d) => {
    const finalDescription = d.trim()

    try {
      const { data } = await axios.post('/api/user/talent/custom-description', {
        description: finalDescription,
        influencer_id: params.influencerId,
      })
      dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
    } catch (e) {
      console.log(e)
      setError('Something is wrong')
    }
  }

  const saveNewTagline = async (tl) => {
    const finalTagline = tl.trim()
    if (finalTagline.length > 30) {
      return setTaglineError('Tagline must be less than 30 characters')
    }

    try {
      await axios.post('/api/user/talent/custom-tagline', {
        tagline: finalTagline,
        influencer_id: params.influencerId,
      })
      dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
    } catch (e) {
      console.log(e)
      setError('Something went wrong')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const savingDescription = newDescription || previousDescription
    const finalDescription = savingDescription.trim()

    saveNewDescription(finalDescription).then(() => {
      showSavedToast()
    })
  }

  return (
    <div className="container pt-4">
      <div className="row justify-content-center mx-1">
        <h2 className="mb-5 text-center">Custom Description for {profile.fullname}</h2>
        <div className="row justify-content-center mb-3">
          <div className="col-4">
            <div className="d-flex flex-column justify-content-center">
              {/* <div>
                <p className="text-center fw-bold">{profile.tagline ? profile.tagline : ''}</p>
              </div> */}
              <div className="portrait-rounded">
                <img src={talent.getPhotoUrl()} />
              </div>
            </div>
          </div>
          <div className="col-8 bg-white p-3 rounded">
            <p>Default description:</p>
            {(profile.description || '').split('\n').map((line, i) => {
              return <p key={`line-${i}`}>{line}</p>
            })}
          </div>
        </div>
        {!_.isEmpty(previousDescription) && (
          <>
            <div className="row justify-content-center mb-3">
              <div className="col-10 col-sm-8 bg-white p-3 rounded">
                <p>Current Custom Description:</p>
                <div className="card p-3 shadow-sm">
                  <RenderMarkdown markdownText={previousDescription} />
                </div>
              </div>
            </div>
          </>
        )}
        <hr />
        <>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 bg-white p-3 rounded">
              <form
                className=""
                onSubmit={(e) => {
                  e.preventDefault()
                  if (newTagline.length > 30) {
                    return setTaglineError('Tagline must be less than 30 characters')
                  }

                  saveNewTagline(newTagline).then(() => {
                    showSavedToast({ customText: 'Custom Tagline Saved' })
                  })
                }}
              >
                <div className="mb-0">
                  <label htmlFor="#tagline-input" className="form-label">
                    Tagline (appears on "Roster View" of Talent Sheets)
                  </label>
                  <p>
                    Current:{' '}
                    {profile.tagline ? (
                      profile.tagline
                    ) : (
                      <span className="fst-italic">-none-</span>
                    )}
                  </p>
                  <input
                    id="tagline-input"
                    data-purpose="tagline"
                    className="form-control w-100"
                    placeholder="Artist, model, actor, etc."
                    onChange={(e) => {
                      setError(null)
                      setTagline(e.target.value === '' ? null : e.target.value)
                    }}
                    value={newTagline !== null ? newTagline : ''}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <input
                    disabled={newTagline === null}
                    className="btn btn-primary my-3"
                    type="submit"
                    value="Save"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setTagline('')
                      saveNewTagline('').then(() => {
                        showSavedToast({ customText: 'Custom Tagline Deleted' })
                      })
                    }}
                    className="btn btn-danger my-3 ms-3"
                  >
                    Remove Tagline
                  </button>
                </div>
                {taglineError && <p className="text-danger">{taglineError}</p>}
              </form>
            </div>
          </div>
        </>

        <div className="row justify-content-center mt-3">
          <div className="col-10 col-sm-8 bg-white p-3 rounded">
            <form className="talent-description-form" onSubmit={handleSubmit}>
              <label htmlFor="#cd-input" className="form-label">
                New Custom Description
              </label>
              <div className="mb-3">
                <textarea
                  id="cd-input"
                  data-purpose="custom-description"
                  className="form-control w-100"
                  style={{ height: '180px' }}
                  autoFocus
                  placeholder="your description here..."
                  onChange={(e) => {
                    setError(null)
                    setDescription(e.target.value)
                  }}
                  value={newDescription !== null ? newDescription : previousDescription}
                ></textarea>
              </div>
              <Alert variant="info">
                <p className="my-2">
                  <span className="fw-bold">Include links using this format:</span>
                  <br />
                  <br />
                  <span>[Text to Display](https://your-website-link-here.com/)</span>
                </p>
              </Alert>

              <div className="d-flex justify-content-center">
                <input className="btn btn-primary my-3" type="submit" value="Save" />
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setDescription('')
                    if (window.confirm('Remove current custom description and use the default?')) {
                      saveNewDescription('').then(() => {
                        showSavedToast({ customText: 'Custom Description Deleted' })
                      })
                    }
                  }}
                  className="btn btn-danger my-3 ms-3"
                >
                  Delete
                </button>
              </div>
            </form>
            <div className="message-box">
              {error && <div className="error">{error}</div>}
              {notification && <div className="success">{notification}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <p>
            <Link data-purpose="profile-link" to={`/influencers/profile/${params.influencerId}`}>
              Back to profile
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default CustomDescription
