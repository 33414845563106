const formatAsPct = (num) => {
  return `${(num * 100).toFixed(1)}%`
}

const pythonFormToDisplay = (s) => {
  // replace underscores with spaces and capitalize first letter of each word
  return s.replace(/_/g, ' ').replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

const AudienceTypeBox = ({ data }) => {
  if (!data.audience_types) return null
  return (
    <>
      <h4 className="text-center headline mb-3">Audience Types</h4>
      <div className="country-list d-flex flex-column justify-content-between">
        {data.audience_types.map((audience_type, i) => {
          return (
            <div
              key={`audience-type-${i}`}
              className="d-flex justify-content-between align-items-center"
            >
              <span>{pythonFormToDisplay(audience_type.code)}</span>
              <span>{formatAsPct(audience_type.weight)}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AudienceTypeBox
