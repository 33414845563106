import { useState } from 'react'

import { Blocks } from 'react-loader-spinner'
import _ from 'lodash'
import axios from 'axios'
import CommonFormWrapper from 'components/CommonFormWrapper'
import { useSelector } from 'react-redux'

function AddTalent() {
  const blankForm = {
    name: '',
    handle: '',
    socials: {
      primary: {
        id: 'primary',
        platform: 'Instagram',
        url: '',
      },
    },
    note: '',
  }

  const platforms = {
    instagram: {
      name: 'Instagram',
      value: 'instagram',
    },
    tiktok: {
      name: 'TikTok',
      value: 'tiktok',
    },
    youtube: {
      name: 'YouTube',
      value: 'youtube',
    },
  }

  const [error, setError] = useState(null)
  const [socialRequestState, setSocialRequestState] = useState('idle')

  const [url, setUrl] = useState('')
  const [name, setName] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [editName, setEditName] = useState(false)
  const [socials, setSocials] = useState({})
  const primarySocial = _.find(socials, (social) => social.primary)
  const [platform, setPlatform] = useState(platforms['instagram'])
  const [submitComplete, setSubmitComplete] = useState(false)
  const [unconfirmedSocial, setUnconfirmedSocial] = useState(null)

  const userTeamSettings = useSelector((state) => state.user.teamSettings)

  const resetEverything = () => {
    setSocials({})
    setName('')
    setNameInput('')
    setEditName(false)
    setPlatform(platforms['instagram'])
    setUrl('')
    setSubmitComplete(false)
    setUnconfirmedSocial(null)
    setError(null)
  }

  const checkSocial = async (e) => {
    e.preventDefault()
    setSocialRequestState('loading')
    if (url.trim() === '') {
      setError('You must include a handle or URL')
      return
    }

    try {
      const params = {
        url,
        platform: platform.value,
      }
      const { data } = await axios.get(`/api/check-social`, { params })

      setSocialRequestState('success')

      if (data.existing) {
        setError(`Your team already has that account: ${data.existing}`)
        setUrl('')
        return
      }

      const newSocialKey = `${data.social.platform}-${data.social.handle}`

      const existingKeys = Object.keys(socials)

      if (existingKeys.includes(newSocialKey)) {
        setError('You already added that account')
        return
      } else {
        setSocials({
          ...socials,
          [newSocialKey]: {
            ...data.social,
            primary: !primarySocial,
            display: platform.name,
            key: newSocialKey,
          },
        })
        setUrl('')
        setError(null)
      }
    } catch (e) {
      // console.log(e)
      // console.log(_.get(e, 'response.data.message'))
      const errorMessage = _.get(e, 'response.data.message')
      setSocialRequestState('error')
      setError(
        errorMessage ||
          'Something went wrong trying to process that, please review the information and try again, or contact us if the problem persists.',
      )
    }
  }

  const confirmSocial = (social) => {
    const newSocialKey = `${social.platform}-${social.handle}`
    const existingKeys = Object.keys(socials)
    setUrl('')
    setUnconfirmedSocial(null)

    if (existingKeys.includes(newSocialKey)) {
      setError('You already added that account')
      return
    } else {
      setSocials({
        ...socials,
        [newSocialKey]: {
          ...social,
          key: newSocialKey,
        },
      })
      setError(null)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (Object.keys(socials).length === 0) {
        setError('You must include at least one social platform')
        return
      }

      const formData = {
        name,
        socials: _.map(socials, (social) => {
          return {
            platform: social.platform,
            handle: social.handle,
            primary: social.primary,
          }
        }),
      }

      const response = await axios.post('/api/add-influencer', { influencer: formData })
      setError('')
      setSubmitComplete(true)
    } catch (e) {
      console.log(e)
      if (_.get(e, 'response.data.error') === 'no_credits') {
        setError(
          'Sorry, we have to limit the number of accounts you can add automatically. Please contact us about adding more.',
        )
      } else {
        setError(
          'Something unexpected went wrong, sorry. Please contact us about adding this talent',
        )
      }
    }
  }

  if (userTeamSettings?.credits === 0) {
    return (
      <CommonFormWrapper>
        <h2 className="text-center mb-3">Maximum Roster Reached</h2>
        <p>
          Your team's roster has reached its maximum size. If you wish to add more, please contact
          us to discuss your options.
        </p>
      </CommonFormWrapper>
    )
  }

  return (
    <CommonFormWrapper>
      {submitComplete ? (
        <>
          <h2 className="text-center mb-3">Add Talent Request Submitted</h2>
          <p>
            The information you provided for <strong>{name}</strong> has been received.
          </p>
          <p>
            The account should appear on your roster in one hour or less. However, some accounts
            cannot be added automatically. If the account still has not appeared after two hours,
            contact us and we will investigate the issue.
          </p>
          <p>
            <button className="btn btn-primary" onClick={resetEverything}>
              Add Another
            </button>
          </p>
        </>
      ) : (
        <>
          {_.isEmpty(name) ? (
            <>
              <h1 className="text-center">Add Talent</h1>
              <p className="py-2">
                Provide us with the name and social accounts of the talent you'd like to add to
                your team.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setName(nameInput)
                  setEditName(false)
                }}
              >
                <div className="form-group">
                  <label className="py-2" htmlFor="name">
                    New Talent Name (required - will be displayed on their profile page)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter name"
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    setName(nameInput)
                    setEditName(false)
                  }}
                >
                  Next
                </button>
              </form>
            </>
          ) : (
            <>
              <h1 className="mb-3 text-center">Add Talent</h1>
              <div className="row justify-content-center">
                <div className="col-12 mb-3">
                  {name !== '' && !editName ? (
                    <h3 className="selectable" onClick={() => setEditName(true)}>
                      {name} (click to change name)
                    </h3>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        setName(nameInput)
                        setEditName(false)
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={nameInput}
                        onChange={(e) => setNameInput(e.target.value)}
                      />
                      <div className="d-flex justify-content-center">
                        <input type="submit" className="btn btn-primary mt-2" value="Set Name" />
                      </div>
                    </form>
                  )}
                </div>
                <hr />
                <div className="col-12">
                  <form className="social-lookup-form" onSubmit={checkSocial}>
                    {!primarySocial ? (
                      <p>Talent's primary platform:</p>
                    ) : (
                      <>
                        <p>Talent's primary platform: {primarySocial.display}</p>
                        <p>Additional platform</p>
                      </>
                    )}
                    <select
                      className="form-select mb-2"
                      value={platform.value}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setPlatform(platforms[e.target.value])
                      }}
                    >
                      {Object.keys(platforms).map((key) => (
                        <option key={key} value={key}>
                          {platforms[key].name}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder={`${
                        platform.value === 'youtube' ? '@username' : 'profile URL or username'
                      }`}
                      value={url}
                      onChange={(e) => {
                        setUrl(e.target.value)
                      }}
                    />
                    <div className="d-flex justify-content-center">
                      <input
                        type="submit"
                        className="btn btn-primary my-2"
                        disabled={_.isEmpty(url)}
                        value="Add Platform"
                      />
                    </div>
                    <div className="message-box mt-2">
                      {socialRequestState === 'loading' && (
                        <div className="d-flex justify-content-center">
                          <Blocks
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperClass="blocks-wrapper"
                          />
                        </div>
                      )}
                      {error && <div className="error">{error}</div>}
                    </div>
                  </form>
                  {error && unconfirmedSocial && (
                    <div className="text-center">
                      <button
                        className="btn btn-warning my-2"
                        onClick={() => {
                          confirmSocial(unconfirmedSocial)
                        }}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              {!_.isEmpty(socials) && (
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-center">Verify Information</h3>
                    <div className="d-flex flex-row my-3">
                      <div className="text-bold me-3">Name:</div>
                      <div>{name}</div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">Primary</th>
                          <th className="text-center">Platform</th>
                          <th className="text-center">Handle or Channel</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.map(socials, (social, key) => {
                          return (
                            <tr key={key}>
                              <td
                                className="text-bold text-center selectable"
                                onClick={() => {
                                  const newSocials = _.mapValues(socials, (s) => {
                                    return {
                                      ...s,
                                      primary: social.key === s.key,
                                    }
                                  })
                                  setSocials(newSocials)
                                }}
                              >
                                {social.primary && '✓'}
                              </td>
                              <td className="text-bold text-center">{social.display}</td>
                              <td className="text-center">{social.handle}</td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    const newSocials = _.omit(socials, key)
                                    setSocials(newSocials)
                                  }}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <p>
                      Note: The "primary" account will be used to show the avatar on their profile
                      page, and the statistics for that platform will be displayed by default
                    </p>

                    <p>
                      When you have finished adding platforms, click the button below to add this
                      talent.
                    </p>

                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary my-2"
                        disabled={Object.keys(socials).length === 0 || _.isEmpty(name)}
                        onClick={handleSubmit}
                      >
                        Add Talent To Roster
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </CommonFormWrapper>
  )
}

export default AddTalent
