import { Link, useParams } from 'react-router-dom'
import { fetchSingleInfluencer, setTags, setTalentSheetReaction } from './redux/influencerSlice'
import { fetchBasicInfo } from 'redux/allSheetsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import LoadingContainer from 'components/LoadingContainer'
import PublicProfile from './PublicProfile'
import ReactMarkdown from 'react-markdown'
import AutoSuggest from './components/AutoSuggest'
import _ from 'lodash'
import { addTags } from './redux/tagsSlice'
import axios from 'axios'
import Table from 'react-bootstrap/Table'

import FollowerChart from './components/FollowerChart'
import AudienceTypeBox from 'components/AudienceTypeBox'
import LikesNotFromFollowersWidget from 'components/LikesNotFollowersWidget'
import AddInfluencerTags from 'components/AddInfluencerTags'

export default function InfluencerProfile() {
  const dispatch = useDispatch()
  const params = useParams()

  const selectedInfluencerId = params.influencerId

  // const influencers = useSelector((state) => state.influencers.entities)
  const isLoading = useSelector((state) => state.influencers.loading === 'pending')

  const influencer = useSelector((state) => state.influencers.entities[selectedInfluencerId])
  const socials = useSelector((state) => state.socials.entities)
  const reduxSheets = useSelector((state) => state.allSheets.entities)
  const user = useSelector((state) => state.user)
  const team = useSelector((state) => state.team)

  const commentAreaRef = useRef(null)

  const [teamNotes, setTeamNotes] = useState([])
  const [comment, setComment] = useState('')
  const [editComment, setEditComment] = useState(false)
  const [toRemove, setToRemove] = useState(null)

  const [selectedPlatform, setSelectedPlatform] = useState(null)

  const [extraData, setExtraData] = useState({})

  const [editingClientNote, setEditingClientNote] = useState({
    id: null,
    note: '',
  })

  const [talentSheetPage, setTalentSheetPage] = useState(1)
  const talentSheetPageSize = 70

  const incrementSheetPage = (direction = 'up') => {
    const maxPage = Math.ceil(influencer.salesheet_ids.length / talentSheetPageSize)
    if (direction === 'up' && talentSheetPage < maxPage) {
      setTalentSheetPage(talentSheetPage + 1)
    } else if (direction === 'down' && talentSheetPage > 1) {
      setTalentSheetPage(talentSheetPage - 1)
    }
  }

  const loadTeamNotes = async () => {
    const { data } = await axios.get(`/api/user/influencers/comments/${params.influencerId}`)
    setTeamNotes(data)
  }

  const loadExtraData = async () => {
    const { data } = await axios.get(`/api/user/influencers/extra-data/${params.influencerId}`)

    const extraData = data.socials.reduce((acc, social) => {
      acc[social.id] = social.extra_data
      return acc
    }, {})
    setExtraData(extraData)
  }

  useEffect(() => {
    // only run this if settings.has_extra_data is true
    if (!team.settings?.extraProfileData) return
    loadExtraData()
  }, [team.settings])

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
    loadTeamNotes()
  }, [dispatch])

  if (influencer && influencer.error) {
    return (
      <div className="container mt-3">
        <p>
          There was an error getting this profile. Possibly you do not have access to the account,
          please contact us if you think you should have access
        </p>
      </div>
    )
  }

  if (_.isEmpty(influencer) || _.isEmpty(_.get(socials, influencer.primary_account_id))) {
    return <LoadingContainer />
  }

  const userComment = teamNotes.find((note) => {
    return note.user_id === user.account_id
  })

  const displayedAccountId = selectedPlatform || influencer.primary_account_id

  const socialExtraData = extraData[displayedAccountId] || {}

  return (
    <div>
      <PublicProfile
        influencer={influencer}
        socials={socials}
        openAccess={false}
        user={user}
        settings={influencer.team_settings}
        selectedPlatform={selectedPlatform}
        setSelectedPlatform={setSelectedPlatform}
      />

      {displayedAccountId && extraData[displayedAccountId] && (
        <div className="container mt-4">
          <h3>Extra Data (not shown on public profile)</h3>
          <div className="row">
            <div className="col-12 col-md-4">
              <FollowerChart influencerData={socialExtraData} />
            </div>
            <div className="col-4">
              <AudienceTypeBox data={socialExtraData} />
            </div>
            <div className="col-12 col-md-4">
              <LikesNotFromFollowersWidget data={socialExtraData} />
            </div>
          </div>
        </div>
      )}

      <div className="container mt-4">
        <h3>Tags</h3>
        <div className="w-50">
          <AddInfluencerTags influencer_id={params.influencerId} />

          <p>
            Note: Your team may use pre-defined tags or any custom tags you choose. Custom tags are
            marked with an asterisk/black background. Tags using a blue background are searchable
            by other users if your team's roster is available on the Talent Pitch Pro search tool
          </p>
        </div>
      </div>
      <div className="container mt-4">
        <h3>Team Comments</h3>

        <div className="mb-2">
          {teamNotes.map((note, i) => {
            return (
              <div
                className="card text-dark bg-light mb-3 shadow-sm col-4"
                key={`note-${i}`}
                // style={{ maxWidth: '18rem' }}
              >
                <div className="card-header">
                  {' '}
                  {note.author_nickname}
                  {user.account_id === note.user_id && (
                    <span
                      onClick={() => {
                        setEditComment(true)
                        setComment(note.note)
                      }}
                      className="ms-3 selectable"
                    >
                      (edit)
                    </span>
                  )}
                </div>
                <div className="card-body team-influencer-note bg-white">
                  <div className="card-text">
                    <ReactMarkdown>{note.note}</ReactMarkdown>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <hr />
        {_.isEmpty(userComment) && !editComment && (
          <div>
            <button className="btn btn-primary mb-3" onClick={() => setEditComment(true)}>
              Write Comment
            </button>
          </div>
        )}
        {editComment && (
          <form
            className="d-flex flex-column mb-4"
            onSubmit={(e) => {
              e.preventDefault()
              axios
                .post('/api/user/influencers/comments', {
                  influencer_id: params.influencerId,
                  comment,
                })
                .then(() => {
                  setComment('')
                  setEditComment(false)
                })
                .then(() => {
                  loadTeamNotes()
                })
            }}
          >
            <div>
              <textarea
                className="form-control mb-3"
                data-purpose="influencer-team-comment"
                ref={commentAreaRef}
                style={{
                  width: '33%',
                  height: '120px',
                }}
                type="text"
                placeholder="Add Comment"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value)
                }}
                autoFocus
              />
            </div>

            <div>
              <input className="ms-2 btn btn-primary" value="Save" type="submit" />
              <button
                className="ms-2 btn btn-danger"
                onClick={(e) => {
                  e.preventDefault()
                  setEditComment(false)
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
      {!_.isEmpty(influencer.salesheet_ids) && (
        <div className="container mt-4">
          <h3 className="">Talent Sheets ({influencer.salesheet_ids.length} total)</h3>
          {talentSheetPageSize < influencer.salesheet_ids.length && (
            <>
              <div className="mb-2">
                <p className="text-bold">
                  Showing {1 + (talentSheetPage - 1) * talentSheetPageSize}-
                  {Math.min(
                    talentSheetPage * talentSheetPageSize,
                    influencer.salesheet_ids.length,
                  )}
                </p>

                <button
                  className="btn btn-light mb-3 me-3"
                  onClick={() => incrementSheetPage('down')}
                >
                  {`Previous`}
                </button>
                <button className="btn btn-light mb-3" onClick={() => incrementSheetPage('up')}>
                  {`Next`}
                </button>
              </div>
            </>
          )}

          <Table hover>
            <thead>
              <tr>
                <th scope="col" className="">
                  Title
                </th>
                <th scope="col" className="text-center">
                  Accepted by Client
                </th>
                <th style={{ width: '40%' }} scope="col" className="text-center">
                  Notes
                </th>
                <th scope="col" className="text-center">
                  Link
                </th>
                {toRemove && (
                  <th scope="col" className="text-center">
                    Remove
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {influencer.salesheet_ids
                .slice(
                  (talentSheetPage - 1) * talentSheetPageSize,
                  talentSheetPage * talentSheetPageSize,
                )
                .map((sheet_id, sheet_index) => {
                  const sheet = reduxSheets[sheet_id]
                  const sheet_notes = _.get(influencer, ['sheet_notes', sheet_id], {})
                  if (!sheet) return null
                  return (
                    <tr key={`sheet-${sheet.id}`}>
                      <td>{sheet.title}</td>
                      <td
                        className="selectable text-center"
                        onClick={() => {
                          axios
                            .post('/api/user/influencer/talent-sheet/client-response', {
                              sheet_id: sheet.id,
                              influencer_id: params.influencerId,
                              client_accepted: !sheet_notes.client_accepted,
                            })
                            .then((res) => {
                              dispatch(
                                setTalentSheetReaction({
                                  sheet_id,
                                  influencer_id: params.influencerId,
                                  updates: {
                                    client_accepted: res.data.client_accepted,
                                  },
                                }),
                              )

                              // dispatch(fetchInfluencer(params.influencerId))
                            })
                        }}
                      >
                        {!!sheet_notes.client_accepted && '✔'}
                      </td>
                      <td>
                        {editingClientNote.id === sheet.id ? (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              axios
                                .post('/api/user/influencer/talent-sheet/client-response', {
                                  sheet_id: sheet.id,
                                  influencer_id: params.influencerId,
                                  client_note: editingClientNote.note,
                                })
                                .then((res) => {
                                  dispatch(
                                    setTalentSheetReaction({
                                      sheet_id,
                                      influencer_id: params.influencerId,
                                      updates: {
                                        client_note: res.data.client_note,
                                      },
                                    }),
                                  )
                                  setEditingClientNote({
                                    id: null,
                                    note: '',
                                  })
                                })
                            }}
                          >
                            <div className="d-flex flex-row align-content-center">
                              <textarea
                                className="form-control"
                                style={{
                                  height: '6em',
                                }}
                                placeholder="Add Note"
                                value={editingClientNote.note || ''}
                                onChange={(e) =>
                                  setEditingClientNote({
                                    ...editingClientNote,
                                    note: e.target.value,
                                  })
                                }
                              />
                              <button className="btn btn-primary btn-sm ms-2" type="submit">
                                Save
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div
                            className="selectable"
                            onClick={() => {
                              setEditingClientNote({
                                id: sheet.id,
                                note: sheet.client_note,
                              })
                            }}
                          >
                            {_.get(influencer, ['sheet_notes', sheet_id, 'client_note']) ? (
                              <p className="text-center">
                                {influencer.sheet_notes[sheet_id].client_note}
                              </p>
                            ) : (
                              <p className={`text-center`} style={{ fontStyle: 'italic' }}>
                                Click to add note
                              </p>
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        <Link to={`/talent-sheet/${sheet.id}`}>Link</Link>
                      </td>
                      {toRemove && (
                        <td
                          className="text-center selectable"
                          onClick={() => {
                            if (toRemove.includes(sheet.id)) {
                              setToRemove(toRemove.filter((id) => id !== sheet.id))
                            } else {
                              setToRemove([...toRemove, sheet.id])
                            }
                          }}
                        >
                          {toRemove.includes(sheet.id) && '✔'}
                        </td>
                      )}
                    </tr>
                  )
                })}
            </tbody>
          </Table>

          <div className="d-flex w-100 justify-content-end mb-3">
            {toRemove === null ? (
              <button
                className="btn btn-danger"
                onClick={() => {
                  setToRemove([])
                }}
              >
                Remove Multiple
              </button>
            ) : (
              <>
                <button
                  className="btn btn-secondary me-3"
                  onClick={() => {
                    console.log('cancel removing')
                    console.log('toRemove', toRemove)
                    setToRemove(null)
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    if (toRemove.length === 0) return
                    if (
                      !window.confirm(
                        'Are you sure you want to remove the influencer from these sheets?',
                      )
                    )
                      return
                    axios
                      .post('/api/user/talent-sheets/remove-influencer', {
                        influencer_id: params.influencerId,
                        sheet_ids: toRemove,
                      })
                      .then((res) => {
                        dispatch(fetchBasicInfo())
                        dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))

                        setToRemove(null)
                      })
                  }}
                >
                  Remove from Selected Sheets
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
