import AutoSuggest from './AutoSuggest'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export default function InfluencerFilters({
  filteringTags,
  setFilteringTags,
  setNameFilter,
  allowHide = true,
}) {
  const suggestedTags = useSelector((state) => state.tags.tags)
  const [nameInput, setNameInput] = useState('')
  const [openSearch, setOpenSearch] = useState(true)

  const handleAddTagFromForm = (tag) => {
    const newTags = [...filteringTags, tag]

    setFilteringTags(newTags)
  }

  let handleChangeName = (value) => {
    setNameFilter(value)
  }

  handleChangeName = _.debounce(handleChangeName, 600)

  return (
    <>
      {!allowHide || openSearch ? (
        <>
          <p className="w-50">
            <input
              className="form-control form-control-sm"
              placeholder="filter by name..."
              value={nameInput}
              onChange={(e) => {
                setNameInput(e.target.value)
                handleChangeName(e.target.value)
              }}
            />
          </p>

          <div className="w-50">
            <AutoSuggest
              handleAddTagFromForm={handleAddTagFromForm}
              currentTags={filteringTags}
              handleRemoveTag={(t) => {
                const newTags = _.without(filteringTags, t)
                setFilteringTags(newTags)
              }}
              allChoices={suggestedTags}
              inputPlaceholder="filter by tag..."
            />
          </div>

          <p>
            <button
              onClick={() => {
                setNameFilter('')
                setFilteringTags([])
                setOpenSearch(false)
              }}
              className="btn btn-primary btn-sm mt-2"
            >
              Clear Filters
            </button>
          </p>
        </>
      ) : (
        <p>
          <button
            onClick={() => {
              setOpenSearch(true)
            }}
            className="btn btn-primary btn-sm"
          >
            Filter
          </button>
        </p>
      )}
    </>
  )
}
