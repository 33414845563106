import _, { map } from 'lodash'

import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import SocialIcon from './components/SocialIcon'
import { Talent } from 'helpers/Talent'
import AddInfluencerTags from 'components/AddInfluencerTags'
import { useState } from 'react'
import RenderMarkdown from 'components/RenderMarkdown'

export default function TalentRosterTable({
  displayedInfluencers,
  socials,
  orderBy,
  setOrderBy,
  profileType = 'influencers',
  settings = {},
}) {
  const [editInfluencerTags, setEditInfluencerTags] = useState(null)

  const tableColumns = [
    {
      th: <th scope="col">PHOTO</th>,
      td: (influencer) => {
        const talent = new Talent(influencer, socials)
        return (
          <td className="portrait-rounded align-middle selectable">
            <div className="margin">
              <Link to={`/${profileType}/profile/${influencer.id}`}>
                <img data-purpose="influencer-portrait" src={talent.getPhotoUrl()} />
              </Link>
            </div>
          </td>
        )
      },
    },
    {
      th: (
        <th
          className="selectable"
          onClick={() => {
            if (orderBy === 'fullname') {
              setOrderBy(null)
            } else {
              setOrderBy('fullname')
            }
          }}
          scope="col"
        >
          NAME {orderBy === 'fullname' && '▼'}
        </th>
      ),
      td: (influencer, settings = {}) => {
        const displayName = influencer.team_settings?.custom_display_name
          ? influencer.team_settings.custom_display_name
          : influencer.fullname

        return (
          <td data-item={'influencer-name'} className="">
            <Link
              to={`/influencers/profile/${influencer.id}`}
              className={`text-${settings.light ? 'black' : 'white'} text-decoration-none`}
            >
              {displayName}
            </Link>
          </td>
        )
      },
    },
    {
      th: (
        <th style={{ maxWidth: '500px' }} scope="col">
          DESCRIPTION
        </th>
      ),
      td: (influencer) => {
        const description = _.isEmpty(influencer.custom_description)
          ? _.get(influencer, 'description', '')
          : _.get(influencer, 'custom_description', '')

        return (
          <td style={{ maxWidth: '500px' }} className="">
            <RenderMarkdown markdownText={description} textColor={'white'} />
            {/* {!_.isEmpty(description) &&
              description.split('\n').map((line, i) => {
                return <p key={`line-${i}`}>{line}</p>
              })} */}
          </td>
        )
      },
    },
    {
      th: (
        <th style={{ maxWidth: '400px' }} scope="col">
          TAGS
        </th>
      ),
      td: (influencer) => {
        return (
          <td style={{ maxWidth: '400px' }} data-item={'influencer-tags'}>
            {editInfluencerTags !== influencer.id ? (
              <>
                {!_.isEmpty(_.get(influencer, 'tags')) ? (
                  <>
                    {influencer.tags.map((t, i) => {
                      return (
                        <div
                          key={`tag-${i}`}
                          className="tag badge rounded-pill bg-info text-white mb-2 py-2 selectable"
                          onClick={() => {
                            setEditInfluencerTags(influencer.id)
                          }}
                        >
                          {t}
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setEditInfluencerTags(influencer.id)
                    }}
                  >
                    Edit Tags
                  </button>
                )}
              </>
            ) : (
              <>
                <AddInfluencerTags influencer_id={influencer.id} />
              </>
            )}
          </td>
        )
      },
    },
    {
      th: <th scope="col">SOCIAL ACCOUNTS</th>,
      td: (influencer, settings = {}) => {
        const talent = new Talent(influencer, socials)
        const talentSocials = talent.getSocials()

        const influencerSocials = talentSocials.allSocials

        return (
          <td>
            <div className="d-flex flex-column team-roster-social-list">
              {influencerSocials.map((social, i) => {
                if (!social) return null
                return (
                  <div className="" key={`influencer-social-${i}`}>
                    <a
                      style={{ color: settings.light ? 'black' : 'white' }}
                      target="_blank"
                      href={social.profile_url}
                    >
                      <SocialIcon platform={social.platform} /> {social.platform_account_nickname}
                    </a>
                  </div>
                )
              })}
            </div>
          </td>
        )
      },
    },
  ]

  return (
    <div className="container" style={{ maxWidth: '100%' }}>
      <table
        style={{
          width: '100%',
        }}
        className={`table table-striped table-${
          settings.displayTableColor ? settings.displayTableColor : 'dark'
        } mt-1 influencers-table`}
      >
        <thead>
          <tr className="">
            {tableColumns.map((col, i) => {
              return <Fragment key={`th-${i}`}>{col.th}</Fragment>
            })}
          </tr>
        </thead>
        <tbody>
          {map(displayedInfluencers, (influencer, i) => {
            return (
              <tr data-influencer-id={influencer.id} key={`influencer-${influencer.id}`}>
                {tableColumns.map((col, i) => {
                  return (
                    <Fragment key={`td-${influencer.id}-${i}`}>
                      {col.td(influencer, settings)}
                    </Fragment>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
