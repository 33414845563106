import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import routes from './routes'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import BulletPoint from 'components/BulletPoint'
import { disableNotification } from 'redux/conversationSlice'
import { IoMail, IoMailOutline } from 'react-icons/io5'

import config from './config'

const RenderNavLink = ({ route, title }) => {
  const location = useLocation()

  const isActive = location.pathname === route

  return (
    <Nav.Link
      as={Link}
      className={`py-2 mx-2 ${isActive ? 'active' : ''} ${
        location.pathname === route ? 'text-bold' : 'text-white'
      }`}
      to={route}
    >
      {title}
    </Nav.Link>
  )
}

export default function TPPNavbar() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const conversations = useSelector((state) => state.conversations)

  const signOut = () => {
    axios.get('/api/user/signout').then(({ data }) => {
      window.location.href = '/'
    })
  }

  if (!user.authed || user.noTeam) {
    return null
  }

  const hasUnreadConversation = conversations.unread_conversations.length > 0
  const hasConversations = conversations.conversations.ids.length > 0

  const showNotification =
    conversations.unread_conversations.length > 0 && !conversations.user_acknowledged_notification

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg={_.get(user, 'teamSettings.navBarColor') || 'tpp-dark-blue'}
        variant="dark"
        className={`d-print-none`}
      >
        <div className="container-fluid py-0">
          <Nav className="me-auto">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {routes
                .filter((r) => r.hasNavlink)
                .map((route, i) => {
                  if (route.shouldShow && !route.shouldShow(user)) return null

                  return (
                    <RenderNavLink key={`route-${i}`} route={route.route} title={route.title} />
                  )
                })}
            </Navbar.Collapse>
          </Nav>
          {user.authed && (
            <Nav className="me-3">
              {hasConversations && (
                <Nav.Link as={NavLink} to={`/messages`}>
                  <span className="me-3 d-none d-md-block">
                    {hasUnreadConversation ? (
                      <IoMail color={config.colors['shine-gold']} size={30} />
                    ) : (
                      <IoMailOutline color={config.colors['shine-gold']} size={30} />
                    )}
                  </span>
                </Nav.Link>
              )}
              <Dropdown align={'end'}>
                <Dropdown.Toggle variant="tpp-blue" id="dropdown-basic" className="text-white">
                  {showNotification && <BulletPoint className="" color="red" />} More
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate('/configure')}>Settings</Dropdown.Item>
                  {_.get(user, 'teamSettings.hasAddInfluencer') && (
                    <Dropdown.Item onClick={() => navigate('/add-talent')}>
                      Add Talent{' '}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={() => navigate('/contact-tpp')}>
                    Contact Us
                  </Dropdown.Item>
                  {conversations.conversations.ids.length > 0 && (
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(disableNotification())
                        navigate('/messages')
                      }}
                    >
                      Messages {showNotification && <BulletPoint className="" color="red" />}
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => navigate('/talent-sheets/statistics')}>
                    Statistics
                  </Dropdown.Item>
                  <Dropdown.Item onClick={signOut} href="#">
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          )}
        </div>
      </Navbar>

      {/* <hr /> */}
    </>
  )
}
