import _ from 'lodash'
import axios from 'axios'
import produce from 'immer'
import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import CommonFormWrapper from 'components/CommonFormWrapper'
import ConfirmModal from 'components/ConfirmModal'
import { removeInfluencerById } from 'redux/influencerSlice'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css' // import the css for cropperjs
import { ProgressBar } from 'react-loader-spinner'
import { Talent } from 'helpers/Talent'

const RenderPortrait = ({ setUploadedKey, uploadedKey }) => {
  return (
    <div className="d-flex justify-content-center my-2">
      <div className="d-flex flex-column">
        <div className="text-center">
          <img
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '200px',
              height: '200px',
              borderRadius: '50%',
            }}
            src={uploadedKey}
            alt="uploaded"
          />
        </div>

        {setUploadedKey && (
          <>
            <p className="text-bold mt-2">
              This portrait will replace the one currently shown on the profile page
            </p>
            <div className="text-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault()
                  setUploadedKey(null)
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ImageUploadAndCrop = ({ uploadedKey, setUploadedKey, imageSrc, setImageSrc }) => {
  const [cropper, setCropper] = useState(null)
  const [error, setError] = useState(null)
  const [uploadPending, setUploadPending] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: async (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )

      const file = acceptedFiles[0]
      setImageSrc(file.preview)
    },
  })

  const uploadPhoto = async (file) => {
    if (!file) return
    try {
      const { data } = await axios.get('/api/media-upload', {
        params: {
          filename: file.name,
          type: file.type,
        },
      })

      const upload = await axios.put(data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      })

      setUploadedKey(data.key)
    } catch (e) {
      console.log(e)
      setError('Something went wrong with the upload.')
    }
  }

  const cropImage = async (e) => {
    e.preventDefault()
    if (typeof cropper !== 'undefined') {
      // Get cropped image
      let canvas = cropper.getCroppedCanvas()

      // If the original dimensions are greater than 600px, resize the image
      if (canvas.width > 600 || canvas.height > 600) {
        let maxCanvas = document.createElement('canvas')
        let ctx = maxCanvas.getContext('2d')

        // Get the aspect ratio of the image
        let ratio = Math.min(600 / canvas.width, 600 / canvas.height)

        // Set the dimensions of the new canvas
        maxCanvas.width = canvas.width * ratio
        maxCanvas.height = canvas.height * ratio

        // Draw the image onto the new canvas, effectively resizing it
        ctx.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          maxCanvas.width,
          maxCanvas.height,
        )

        // Replace the original canvas with the resized one
        canvas = maxCanvas
      }

      // Convert the canvas to a blob
      const content = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg'))

      const file = new File([content], 'myCroppedPhoto.jpg', { type: 'image/jpeg' })

      // Upload cropped file
      setUploadPending(true)
      await uploadPhoto(file)
      setUploadPending(false)
    }
  }

  if (uploadPending) {
    return (
      <div className="d-flex justify-content-center">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#F4442E"
          barColor="#51E5FF"
        />
      </div>
    )
  }

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className="selectable">Click to select a file from your computer</p>
      </div>
      {imageSrc && (
        <div>
          {uploadedKey ? (
            <div className="d-flex justify-content-center my-2">
              <div className="d-flex flex-column">
                <div className="text-center">
                  <img
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: '200px',
                      height: '200px',
                      borderRadius: '50%',
                    }}
                    src={`https://tpp-uploads.us-east-1.linodeobjects.com/${uploadedKey}`}
                    alt="uploaded"
                  />
                </div>

                <p className="text-bold mt-2">
                  This portrait will replace the one currently shown on the profile page
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      setUploadedKey(null)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-3">
              <Cropper
                src={imageSrc}
                style={{ maxHeight: '600px', width: '100%' }}
                aspectRatio={1}
                guides={true}
                onInitialized={(instance) => {
                  setCropper(instance)
                }}
              />
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary mt-2" onClick={cropImage}>
                  Confirm Portrait Framing
                </button>
                <button
                  className="btn btn-danger mt-2 ms-2"
                  onClick={(e) => {
                    e.preventDefault()
                    setImageSrc(null)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  )
}

const removeAtIndex = (arr, index) => {
  return arr.filter((_, i) => i !== index)
}

const isSocialEmpty = (social) => {
  return social.url === ''
}

const socialsEmpty = (socials) => {
  if (socials.length === 0) {
    return true
  }
  const allSocialsEmpty = socials.every(isSocialEmpty)
  return allSocialsEmpty
}

function RequestChange() {
  const params = useParams()
  const navigate = useNavigate()
  const influencer = useSelector((state) => state.influencers.entities[params.influencerId])
  const dispatch = useDispatch()
  const socials = useSelector((state) => state.socials.entities)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [influencerRemoved, setInfluencerRemoved] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [extraUploadKeys, setExtraUploadKeys] = useState([])

  const uploadExtra = async (files) => {
    const newKeys = []
    for (const file of files) {
      try {
        const { data } = await axios.get('/api/media-upload', {
          params: {
            filename: file.name,
            type: file.type,
          },
        })
        await axios.put(data.url, file, {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
        })
        newKeys.push(data.key)
      } catch (e) {
        console.log(e)
        setError('Something went wrong with the upload.')
      }
    }
    setExtraUploadKeys([...extraUploadKeys, ...newKeys])
  }

  const renderUploadedImages = () => {
    return (
      <div className="row">
        {extraUploadKeys.map((key, index) => (
          <div key={key} className="col-md-4 mb-4">
            <div className="card">
              <div className="d-flex justify-content-center">
                <img
                  className="card-img-top"
                  src={`https://tpp-uploads.us-east-1.linodeobjects.com/${key}`}
                  alt="uploaded"
                  style={{ maxWidth: '150px' }}
                />
              </div>

              <div className="card-body">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    const newKeys = [...extraUploadKeys]
                    newKeys.splice(index, 1)
                    setExtraUploadKeys(newKeys)
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadExtra(acceptedFiles)
    },
    [extraUploadKeys],
  )

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const blankForm = {
    influencer_id: params.influencerId,
    socials: [
      {
        platform: 'Instagram',
        url: '',
      },
    ],
    note: '',
  }

  const [data, setData] = useState(blankForm)
  const [socialCounter, setSocialCounter] = useState(1)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)

  const [uploadedKey, setUploadedKey] = useState(null)

  const handleRemove = async (influencer_id) => {
    try {
      await axios.post('/api/user/team/roster/remove', {
        influencer_id,
      })
    } catch (e) {
      setError('Something went wrong, sorry. Please contact us about removing talent')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const formData = {
        ...data,
        request_type: 'update',
        uploadedKey,
        socials: _.map(data.socials, (social) => {
          return {
            platform: social.platform.toLowerCase(),
            url: social.url,
          }
        }),
      }

      if (
        socialsEmpty(formData.socials) &&
        _.isEmpty(uploadedKey) &&
        _.isEmpty(formData.note.trim())
      ) {
        setError(
          'It looks like you are not sending any data with this form. Please fill out at least one field',
        )
        return
      }

      if (imageSrc && !uploadedKey) {
        setError('Please confirm the portrait framing before submitting')
        return
      }

      axios.post('/api/user/request-influencer', formData).then((r) => {
        setFormSent(true)
        setData(blankForm)
      })

      return
    } catch (e) {
      console.log(e)
      setError('Something went wrong, sorry. Please contact us about adding an talent')
    }
  }

  const getRemainingPlatforms = () => {
    return ['Instagram', 'TikTok', 'YouTube']
  }

  if (influencerRemoved) {
    return (
      <CommonFormWrapper>
        <h2 className="text-center">Removed</h2>
        <p>We have received your request and will remove the profile from the roster.</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate(`/influencers`)
          }}
        >
          Back to Roster
        </button>
      </CommonFormWrapper>
    )
  }

  if (!influencer) {
    return <div>Loading...</div>
  }

  const changePhoto = async () => {
    const result = axios.post('/api/user/replace-portrait', {
      influencer_id: influencer.id,
      uploadedKey,
    })
  }

  const talentEntity = new Talent(influencer, socials)

  return (
    <CommonFormWrapper>
      {formSent ? (
        <>
          {influencerRemoved ? (
            <>
              <h2 className="text-center">Removed</h2>
              <p>We have received your request and will remove the profile from the roster.</p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate(`/influencers`)
                }}
              >
                Back to Roster
              </button>
            </>
          ) : (
            <>
              <h2 className="text-center">Submitted</h2>
              <p>
                We have received your request and will modify the profile accordingly. It should be
                updated within 24 hours.
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate(`/influencers/profile/${influencer.id}`)
                }}
              >
                Back to Profile
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <h1 className="mb-3 text-center">Request Profile Change</h1>
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="text-center">{influencer?.fullname}</h3>
              <div className="d-flex justify-content-center my-3">
                <button className="btn btn-danger" onClick={() => setShowConfirmModal(true)}>
                  Remove from Roster
                </button>
              </div>
              <hr />
              <div className="row text-center">
                <div className="row justify-content-center">
                  <div className="col-12">
                    {!uploadedKey && <RenderPortrait uploadedKey={talentEntity.getPhotoUrl()} />}
                  </div>
                </div>
                <Link className="btn btn-link" to={`/request/portrait-change/${influencer.id}`}>
                  Change Main Portrait
                </Link>
              </div>

              <hr />
              <form className="influencer-request-form" onSubmit={handleSubmit}>
                <h3 className="text-center">Add/Change Platform(s)</h3>
                {data.socials.map((social, i) => {
                  return (
                    <div key={`social-${i}`} className="mb-3">
                      <select
                        className="form-select mb-1"
                        value={social.platform}
                        onChange={(e) => {
                          const nextData = produce(data, (draftState) => {
                            draftState.socials[i].platform = e.target.value
                          })
                          setData(nextData)
                        }}
                      >
                        {getRemainingPlatforms().map((v) => {
                          return (
                            <option key={`platform-${v.toLowerCase()}`} value={v}>
                              {v}
                            </option>
                          )
                        })}
                      </select>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="profile URL or handle"
                        value={data.socials[i].url}
                        onChange={(e) => {
                          const nextData = produce(data, (draftState) => {
                            draftState.socials[i].url = e.target.value
                          })
                          setData(nextData)
                        }}
                      />
                      {social.id !== 'primary' && (
                        <div className="d-flex w-100 justify-content-end pe-2">
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              const nextData = produce(data, (draftState) => {
                                draftState.socials = removeAtIndex(draftState.socials, i)
                              })
                              setData(nextData)
                            }}
                            className="btn btn-sm btn-danger mt-2"
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  )
                })}
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-success text-white mb-2"
                    onClick={(e) => {
                      e.preventDefault()
                      const nextData = produce(data, (draftState) => {
                        draftState.socials = [
                          ...draftState.socials,
                          {
                            platform: 'Instagram',
                            url: '',
                          },
                        ]
                      })
                      setData(nextData)
                      setSocialCounter(socialCounter + 1)
                    }}
                  >
                    Add Another Social Platform
                  </button>
                </div>

                <hr />
                <div>
                  <h3 className="text-center">Notes</h3>
                  <textarea
                    className="form-control mt-3"
                    rows={5}
                    placeholder="Notes (other platform not listed, name change, social media profile changed, etc.)"
                    value={data.note}
                    onChange={(e) => setData({ ...data, note: e.target.value })}
                  />
                </div>
                <div className="message-box mt-2">
                  {error && <div className="error">{error}</div>}
                </div>

                <div className="d-flex justify-content-center">
                  <input
                    className="btn btn-lg btn-primary my-3"
                    type="submit"
                    value="Submit Request"
                  />
                </div>
              </form>
            </div>
          </div>
          <ConfirmModal
            show={showConfirmModal}
            message={"Are you sure you want to remove this talent from your team's roster?"}
            onConfirm={() => {
              handleRemove(params.influencerId)
              setFormSent(true)
              setShowConfirmModal(false)
              setInfluencerRemoved(true)
              dispatch(removeInfluencerById(params.influencerId))
            }}
            onClose={() => setShowConfirmModal(false)}
          />
        </>
      )}
    </CommonFormWrapper>
  )
}

export default RequestChange
