import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { fetchOneUserSheet } from './userSheetSlice'
import _ from 'lodash'

// Async thunk for fetching casting calls
export const fetchCastingCalls = createAsyncThunk('castingCalls/fetchCastingCalls', async () => {
  // console.log(exampleCalls[0])
  // return {
  //   casting_calls: exampleCalls,
  // }

  const response = await axios.get('/api/casting-calls/agency/casting-calls')
  return response.data
})

export const fetchResponseSheet = createAsyncThunk(
  'castingCalls/fetchResponseSheet',
  async (id, thunkAPI) => {
    const response = await axios.get(`/api/casting-calls/response/${id}`)
    if (!_.isEmpty(response.data.talent_sheet_id)) {
      thunkAPI.dispatch(
        fetchOneUserSheet({
          hash_id: response.data.talent_sheet_id,
          sheet_type: 'casting_call_response',
        }),
      )
    }
    return response.data
  },
)

// Slice for casting calls
const castingCallsSlice = createSlice({
  name: 'castingCalls',
  initialState: [],
  reducers: {
    // Reducer for adding a response sheet to a casting call
    addResponseSheet: (state, action) => {
      const { castingCallId, responseSheetId } = action.payload
      const castingCall = state.find((castingCall) => castingCall.id === castingCallId)
      castingCall.response_sheet_id = responseSheetId
    },
    setShowResponded: (state, action) => {
      console.log('action', action)
      return
      state.showResponded = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCastingCalls.fulfilled, (state, action) => {
      return action.payload.casting_calls
    })
  },
})

// Export the addResponseSheet action
export const { addResponseSheet, setShowResponded } = castingCallsSlice.actions

export default castingCallsSlice.reducer
